import { useStore } from './store'
import buoys from './waypoints'
import { useEffect } from 'react'
import { flatten } from 'rambda'

export default function useWaypoints(userId) {
	let [waypoints, setWaypoints] = useStore('waypoints', buoys)
	useEffect(() => {
		if (userId) {
			console.log('GET marks', userId)
			fetch('https://raceqs.com/rest/marks?userId=' + userId)
			.then(x => x.json())
			.then(files => {
				console.log('GET marks OK', files)
				let ws = flatten(Object.keys(files).map((key, i) => files[key].map((x, j) => {
					if (x.type === '-') {
						let prev = files[key][j - 1]
						if (prev) {
							prev.gate = x
						}
					}
					x.id = `${i}.${j}`
					x.group = key
					return x
				})))
				.filter(x => x.type !== '-')
				setWaypoints(waypoints => [...waypoints.filter(x => !x.group), ...ws])
			})
			.catch(e => console.log('GET marks ERR', e))
		}
	}, [userId, setWaypoints])
	return [waypoints, setWaypoints]
}
