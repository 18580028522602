import { default as MUButton} from '@material-ui/core/Button';
import useLongPress from "../useLongPress";
// import styled from 'styled-components'

export function SvgButton({ x, y, text, size={height: 80, width:200 }, color="black", background="#e1e1e1", onClick, onLongPress }) {    
    let props = {text, size, color, background, onClick, onLongPress}
    return (
        <foreignObject x={x} y={y} width={size.width+5} height={size.height+5} fontSize={size.height-15}>
            <Button  {...props}/>
        </foreignObject>  
    )
}

export function Button({ zIndex, text, fontSize, size={height: 80, width:200 }, color="black", background="#e1e1e1", onClick, onLongPress }) {
    
    if (!onLongPress) onLongPress = onClick
    let feedback = () => window.navigator.vibrate(200) 
    const longPress = useLongPress(
        () => {feedback(); onLongPress()}, 
        () => {feedback(); onClick()}
    )
  
    return (
        <MUButton variant="contained" 
            style={{ 
				fontFamily: 'Arial Narrow',
                fontSize: (isFinite(fontSize) && fontSize) || size.height-6, 
                height: size.height, 
                width: size.width||size.height, 
                background, 
                color, 
                borderRadius: 10,   
                fontWeight: 600,
                pointerEvents: "auto",
                zIndex,
            // boxShadow: `5px 2px 5px ${color.back}`,    
            // lineHeight: 0,
            // outline: 0,        
            }}
            //  onClick={() => { feedback(); callback() }} 
            {...longPress}
        >
            {text}
        </MUButton>
    )
}


// export function MyButton({ className, zIndex, text, size={height: 80, width:200 }, color="black", background="#e1e1e1", callback, longPress }) {
    
//     return (
//         <MUButton variant="contained" 
//             style={{ 
//                 fontSize: size.height-6, 
//                 height: size.height, 
//                 width: size.width||size.height, 
//                 background, 
//                 color, 
//                 borderRadius: 10,   
//                 fontWeight: 600,
//                 pointerEvents: "auto",
//                 zIndex,
//             // boxShadow: `5px 2px 5px ${color.back}`,    
//             // lineHeight: 0,
//             // outline: 0,        
//             }} onClick={callback} {...longPress} 
//             className={className}
//         >
//             {text}
//         </MUButton>
//     )
// }

// const Button = styled(MyButton)`    
//     span {
//             height: 100%
//     }
// `

// export { Button, SvgButton }

 
