let themes = {
	dark: {
		background: 'black',
		primary: 'white',
		secondary: 'grey',
	},
	light: {
		background: 'white',
		primary: 'black',
		secondary: '#CFCFCF',
	}
}

export default themes