// @ts-check
import { signum, round } from './tools'
import { Coordinate } from 'coordinate-systems'

// x = { lat: 25, lon: -40 }; calcDistance(x, calcDestination(x, 90.000000001, 92))

export let EARTH_RADIUS = 6378137
export let NMI = 1852

export let standardArithmetic = {
	'+': (a, b) => a + b,
	'-': (a, b) => a - b,
}

export let angularArithmetic = {
    '+': (a, b) => angle360(a + b),
  	'-': (a, b) => calcShift(b, a),
}

export let angularArithmetic180 = {
	'+':  (a, b) => angle180(a + b),
	'-':  (a, b) => calcShift(b, a),
}

/**
 * @param {number} factor
 * @param {number} last
 * @param {number} next
 * @param {object} arithmetic
 * @return {number}
 */
export function lowPass(factor, last, next, arithmetic = standardArithmetic) {
	return arithmetic['+'](last, factor * arithmetic['-'](next, last))
}

/**
 * @param {number[]} xs
 * @return {number}
 */
export function avgAngle(xs) {
    var a = 0
    xs.forEach((x, i) => a += calcShift(a, x) / (i + 1))
    return a
}

/**
 * @param {number} wind
 * @param {number} heading
 * @param {boolean} [spinnaker]
 * @return {string}
 */
export function calcPos(wind, heading, spinnaker = true) {
    if (wind == null) return ''
    var a = calcShift(wind, heading)
    var b = a > 0 ? 'S' : 'P'
    a = Math.abs(a)
    return (a < 30 && spinnaker ? 'SR' : /*a < 30 ? 'GR' :*/ a < 80 && spinnaker ? 'BR' : a < 120 ? 'CR' : 'CH') + b
}

/**
 * @param {string} p
 * @return {string}
 */
export function posTack(p) {
    return p[2]
}

/**
 * @param {string} p
 * @return {boolean}
 */
export function posSpin(p) {
    return p[0] !== 'C'
}

/**
 * @param {string} p
 * @return {string}
 */
export function posKind(p) {
    return p.substr(0, 2)
}

/**
 * @param {string} p
 * @return {number}
 */
export function posSign(p) {
    switch(posKind(p)) {
        case 'CH': return  1
        case 'CR':
        case 'BR': return  0
        case 'SR': return -1
        default  : return  undefined
    }
}

/**
 * @param {string} p
 * @return {boolean}
 */
export function posUp(p) {
    return posKind(p) === 'CH'
}

/**
 * @param {string} p
 * @return {boolean}
 */
export function posDown(p) {
    return posKind(p) === 'SR'
}

/**
 * @param {string} p
 * @return {boolean}
 */
export function posReach(p) {
    var k = posKind(p)
    return k === 'CR' || k === 'BR'
}

/**
 * @param {number} value
 * @return {number}
 */
export function toRadians(value) {
    return value * Math.PI / 180
}

/**
 * @param {number} value
 * @return {number}
 */
export function toDegrees(value) {
    return value / Math.PI * 180
}

/**
 * @param {number} angle
 * @return {number}
 */
export function angle360(angle) {
    while (angle > 360) angle -= 360
    while (angle <   0) angle += 360
    return angle
}

/**
 * @param {number} angle
 * @return {number}
 */
export function angle180(angle) {
    while (angle >  180) angle -= 360
    while (angle < -180) angle += 360
    return angle
}

/**
 * @param {number} b1
 * @param {number} b2
 * @return {number}
 */
export function calcShift(b1, b2) {
    var shift = b2 - b1
    if (shift > +180) shift -= 360
    if (shift < -180) shift += 360
    return shift
}

/**
 * @param {number} a1
 * @param {number} a2
 * @return {number}
 */
export function calcBisector(a1, a2) {
    return angle360(a1 + calcShift(a1, a2) / 2)
}

/**
 * @param {number} d
 * @param {number} t
 * @return {number}
 */
export function calcSpeed(d, t) {
    return ms2kn(d / (t + 1e-9) * 1e3)
}

/**
 * @param {number} x
 * @return {number}
 */
export function ms2kn(x) {
    return x / NMI * 3600
}

/**
 * @param {number} x
 * @return {number}
 */
export function kn2ms(x) {
    return x * NMI / 3600
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @return {number}
 */
export function calcDirection(p1, p2) {
    p2 = calcNearestPoint(p1, p2)
    var lat1 = toRadians(p1.lat), lon1 = toRadians(p1.lon), lat2 = toRadians(p2.lat), lon2 = toRadians(p2.lon)
    var dLon = lon2 - lon1
    var y = Math.sin(dLon) * Math.cos(lat2)
    var x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon)
    var brng = Math.atan2(y, x) / Math.PI * 180
    if (brng < 0) brng += 360
    return brng
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @param {boolean} [line]
 * @return {number}
 */
export function calcDistance(p1, p2, line) {
    p2 = calcNearestPoint(p1, p2, line)
    var lat1 = toRadians(p1.lat), lon1 = toRadians(p1.lon), lat2 = toRadians(p2.lat), lon2 = toRadians(p2.lon)
    var x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2)
    var y = (lat2 - lat1)
    return Math.sqrt(x * x + y * y) * EARTH_RADIUS
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @param {number} direction
 * @return {number}
 */
export function calcProjection(p1, p2, direction) {
    p2 = calcNearestPoint(p1, p2)
    var distance = calcDistance(p1, p2)
    var bearing = calcDirection(p1, p2)
    return Math.cos(toRadians(direction) - toRadians(bearing)) * distance
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @param {boolean} [line]
 * @return {Object}
 */
export function calcNearestPoint(p1, p2, line) {
    if (!p2) return p1.gate
    if (!p2.gate || p2.type === "Mark with offset") return p2

    var A = p1.lat - p2.lat
    var B = p1.lon - p2.lon
    var C = p2.gate.lat - p2.lat
    var D = p2.gate.lon - p2.lon
    var param = (A * C + B * D) / (C * C + D * D)

    if (line || (param > 0 && param < 1)) {
        return { lat: p2.lat + param * C, lon: p2.lon + param * D }
    } else if (param <= 0) {
        return p2
    } else {
        return p2.gate
    }
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @param {Object} p3
 * @return {number}
 */
function calcDLat(p1, p2, p3) {
    if (!p3) return p2.lat - p1.lat
    return p2.lat + (p3.lat - p2.lat) / (p3.lon - p2.lon) * (p1.lon - p2.lon) - p1.lat
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @param {Object} p3
 * @param {Object} p4
 * @return {boolean}
 */
export function calcCross(p1, p2, p3, p4) {
    return signum(calcDLat(p1, p3, p4)) !== signum(calcDLat(p2, p3, p4))
}

/**
 * @param {Object} p1
 * @param {number} bearing
 * @param {number} distance
 * @return {Object}
 */
export function calcDestination(p1, bearing, distance) {
    var d = distance / EARTH_RADIUS
    var lat1 = toRadians(p1.lat), lon1 = toRadians(p1.lon);
    var brng = toRadians(bearing)
    var lat2 = lat1 + d * Math.cos(brng)
    var dLat = lat2 - lat1
    var dPhi = Math.log(Math.tan(lat2/2 + Math.PI/4) / Math.tan(lat1/2 + Math.PI/4))
    var q = Math.abs(dPhi) > 1e-9 ? dLat/dPhi : Math.cos(lat1) // E-W line gives dPhi=0
    var dLon = d * Math.sin(brng) / q
    // check for some daft bugger going past the pole
    if (Math.abs(lat2) > Math.PI/2) lat2 = lat2 > 0 ? Math.PI - lat2 : -(Math.PI - lat2)
    var lon2 = (lon1 + dLon + 3*Math.PI) % (2*Math.PI) - Math.PI
    return { lat: toDegrees(lat2), lon: toDegrees(lon2), alt: p1.alt }
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @return {Object}
 */
export function calcMiddle(p1, p2) {
    if (!p2) p2 = p1.gate
    if (!p2) return p1
    return { lat: (p1.lat + p2.lat) / 2, lon: (p1.lon + p2.lon) / 2, alt: ((p1.alt || 0) + (p2.alt || 0)) / 2 }
}

/**
 * @param {Object} a
 * @param {Object|Number} b
 * @return {Object}
 */
export function extendBounds(a, b) {
    let lat1 = a.lat || a.lat1
	let lon1 = a.lon || a.lon1
	let { lat2, lon2 } = a
    if (typeof(b) === 'object') {
        return {
            lat1: Math.min(lat1, b.lat1)
          , lon1: Math.min(lon1, b.lon1)
          , lat2: Math.max(lat2, b.lat2)
          , lon2: Math.max(lon2, b.lon2)
        }
    } else {
        return {
            lat1: Math.min(lat1, lat2) - b
          , lon1: Math.min(lon1, lon2) - b
          , lat2: Math.max(lat1, lat2) + b
          , lon2: Math.max(lon1, lon2) + b
        }
    }
}

/**
 * @param {Object} p1
 * @param {Object} p2
 * @return {Object}
 */
export function calcDelta(p1, p2) {
  return { lat: p2.lat - p1.lat, lon: p2.lon - p1.lon }
}

export let zero2d = { x: 0, y: 0 }
/**
 * @param {Object} p
 * @param {number} d
 * @param {number} l
 * @return {Object}
 */
export function vector(p, d, l) {
	d = toRadians(d)
	return {
		x: p.x + Math.sin(d) * l, 
		y: p.y + Math.cos(d) * l,
	}
}

/**
 * @param {number} x
 * @return {string}
 */
export function formatDistance(x) {
	return x < 400 ? round(x) + ' m' : x < 10 * NMI ? round(x / NMI, 2) + ' nm' : x < 100 * NMI ? round(x / NMI, 1) + ' nm' : round(x / NMI) + ' nm'
}

/**
 * @param {Object} o
 * @param {number} o.awa
 * @param {number} o.aws
 * @param {number} o.heading
 * @param {number} o.cog
 * @param {number} o.sog
 * @return {Object}
 */
export function calcWind({ awa, aws, heading, cog, sog }) {
	let awd = heading + awa
	const y = sog * Math.sin(toRadians(cog)) - aws * Math.sin(toRadians(awd))
	const x = sog * Math.cos(toRadians(cog)) - aws * Math.cos(toRadians(awd))
	const tws = Math.sqrt(y * y + x * x)
	const twd = toDegrees(Math.atan2(y, x))
	const twa = angle180(twd + 180 - heading)
	awd = angle360(awd + 180)
	return { x, y, awa, awd, aws, twa, twd, tws }
}

/**
 * @param {[number, number]} p
 * @return {[number, number]}
 */
export function polar2cartesian([r, t]) {
	return Coordinate.polar([r, toRadians(t)]).cartesian()
}

/**
 * @param {[number, number]} p
 * @return {[number, number]}
 */
export function cartesian2polar(p) {
	let [r, t] = Coordinate.cartesian(p).polar()
	return [r, toDegrees(t)]
}

/**
 * @param {[number, number]} a
 * @param {[number, number]} b
 * @return {[number, number]}
 */
export function addPolarVectors(a, b) {
	let [x1, y1] = polar2cartesian(a)
	let [x2, y2] = polar2cartesian(b)
	return cartesian2polar([x1 + x2, y1+ y2])
}

/*
function calcVMG(p1, p2, wp) {
  var d1 = calcDistance(p1, wp)
  var d2 = calcDistance(p2, wp)
  return calcSpeed(d1 - d2, p2.time - p1.time)
}
*/
