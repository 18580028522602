// @ts-check
import { useState } from 'react'
import { useEffectOnce } from 'react-use'

/**
 * @return {object}
 */
export default function useGeolocation() {
	const [last, setLast] = useState({ data: {}, status: {} })

	useEffectOnce(() => {
		let set = x => {
			setLast(last => ({
				...last,
				...x,
			}))
		}
		let onPosition = p => set({
			data: {
				time: p.timestamp,
				lat: p.coords.latitude,
				lon: p.coords.longitude,
				accuracy: p.coords.accuracy,
			},
			status: {},
		})
		let onError = error => set({
			status: {
				time: new Date().valueOf(),
				error,
			}
		})
		let watcher = navigator.geolocation.watchPosition(onPosition, onError, { enableHighAccuracy: true })
		return () => navigator.geolocation.clearWatch(watcher)
	})

	return last
}