import { useHistory } from "react-router"
import { forwardRef } from 'react'
import Header from '../Header'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Fab from '@material-ui/core/Fab'
import NavigationIcon from '@material-ui/icons/Navigation'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

const getMarkImg = (type) => {
	switch (type) {
		case 'Start': return '../img/237/ui/panle-start-line-ico.png'
		case 'Finish Up':
		case 'Finish Down':
		case 'Finish': return '../img/237/ui/panel-finish-line-ico.png'
		default: return '../img/237/ui/panel-single-mark-ico.png'
	}
	// gate: '../img/237/ui/panel-gate-ico.png',    
}

let Mark = forwardRef((props, ref) =>
	<ListItem {...props} ref={ref}>
		<ListItemIcon>
			<img src={getMarkImg(props.type)} alt="" />
		</ListItemIcon>
		<ListItemText primary={props.name} />
	</ListItem>
)

let DraggableMark = props => (
	<Draggable draggableId={String(props.index)} index={props.index}>
		{provided => (
			<Mark
				{...props}
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			/>
		)}
	</Draggable>
)

export default function RouteSetup({ waypoints, waypointGroups, waypointGroupSelected, route, setWaypointGroupSelected, onRoute }) {
	const history = useHistory()
	let onAdd = wp => () => onRoute([...route, { waypointId: wp.id, wp }])
	let onRemove = ix => () => onRoute(route.filter((x, j) => j !== ix))
	let onDragEnd = result => {
		let r = Array.from(route)
		const [moved] = r.splice(result.source.index, 1)
		r.splice(result.destination.index, 0, moved)
		onRoute(r)
	}

	const getMarksList = group => (
		waypoints
		.filter(x => x.group === group)
		.map((wp, i) => (
			<Mark
				{...wp}
				onClick={onAdd(wp)}
				key={(group && group + i) || i}
			/>
		))
	)

    return (               
		<div style={{ height: "100%", maxHeight: "100%", display: "flex", flexDirection: "column" }}>
			<Header>Navigate To</Header>
			<div style={{ display: "flex", overflow: "hidden", flex: 1 }}>
				<Paper style={{ flex: 1, overflow: "auto", margin: 10, marginRight: 0 }}>
					<List
						subheader={
							<ListItem>
								{
									waypointGroups.length ? (
										<FormControl style={{ width: "100%" }}>
											<Select value={waypointGroupSelected} onChange={event => setWaypointGroupSelected(event.target.value)} displayEmpty>
												{waypointGroups.map(group => <MenuItem value={group} key={group}>{group}</MenuItem>)}
											</Select>
										</FormControl>
									) : (
										<ListItemText primary="Available marks" style={{ fontSize: 50 }} />
									)
								}
							</ListItem>
						}
					>
						{getMarksList()}
						{/*waypointGroups.length > 0 && <Divider />*/}
						{waypointGroups.length > 0 && getMarksList(waypointGroupSelected)}
					</List>
				</Paper>
				<Paper style={{ flex: 1, overflow: "auto", margin: 10 }}>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="route">
							{(provided) =>
								<List
									subheader={<ListSubheader>Route</ListSubheader>}
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{route.map((leg, ix) =>
										<DraggableMark 
											{...leg.wp}
											index={ix}
											key={ix}
											onClick={onRemove(ix)}
										/>
									)}
									{provided.placeholder}
								</List>
							}
						</Droppable>
					</DragDropContext>
				</Paper>
			</div>
			<Fab onClick={history.goBack} variant="extended" color="primary" style={{ position: 'fixed', top: 'auto', right: 20, bottom: 20, left: 'auto' }}>
				<NavigationIcon />
				Navigate
			</Fab>
		</div>
    )
}