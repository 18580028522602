import { FONT_SIZE, DEVICE_HEIGHT, DEVICE_WIDTH, LINE_WIDTH } from '../settings'
import { Display, NumberDisplays } from '../ViewComponents/NumberDisplays'
import { rx, ry, calcPosFromDistAngle } from "../ViewComponents/tools"
import Cross from '../ViewComponents/Cross'

export default function Efficiency({ posSign, wowa, sow, targetWowa, targetSow, history, theme }) {
	wowa = -wowa
	targetWowa = -targetWowa
	// targetWowa = signum(wowa) * targetWowa
    const center = DEVICE_HEIGHT/2
	// let distTotarget = ({ sow, wowa }) => Math.sqrt(Math.pow(targetSow - sow, 2) + Math.pow((targetWowa - wowa) / 10, 2))
	// let max = history.reduce((a, b) => Math.max(a, distTotarget(b)), 3)
	const scale = DEVICE_HEIGHT / 3 // Math.min(max, 2 * distTotarget({ sow, wowa })) / 3
	const crossSize = { size: 70, thickness: 8 }
    const circleSize = 35
	const dotSize = 25

    const calcScaledPos = (kt, angle) => {
		if (posSign < 0) angle = angle - 180
        return calcPosFromDistAngle(kt, angle, scale, {x: 0, y: center})
    }

    const targetScaled = targetSow && targetWowa && calcScaledPos(targetSow, targetWowa)
    const currentScaled = wowa && sow && calcScaledPos(sow, wowa)
    const currentLineEnding = currentScaled && {
        x: rx(circleSize, wowa + 90 * posSign, currentScaled.x), 
		y: ry(circleSize, wowa + 90 * posSign, currentScaled.y)
    }

    let circles = [], angles = [], dots = [], lastCircle
    for (let i = 1; i <= Math.ceil(targetSow || 10); i ++) {
        circles.push(<circle cx="0" cy={center} r={i * scale} key={i} />)
        lastCircle = i        
    }

    for (let i = 0; i < 360; i += 10) {
        const lineEnding = calcScaledPos(lastCircle, i)
        angles.push(
            <line x1="0" y1={center} x2={lineEnding.x} y2={lineEnding.y} key={i} />
        )          
    }

	for (let i = 0; i < history.length; i++) {
		let circle = calcScaledPos(history[i].sow, -history[i].wowa)
		dots.push(
			<circle 
				cx={circle.x} cy={circle.y} r={dotSize} 
				opacity={1-(i/10)} fill={theme.primary}
				key={history[i].key}
			/>
		)
	}

    return (
        <svg 
			viewBox={`${-DEVICE_WIDTH/2} 0 ${DEVICE_WIDTH} ${DEVICE_HEIGHT}`}  
			style={{ 
                backgroundColor: theme.background, 
                height: "100%", 
                width: "100%", 
                color: theme.primary, 
                fontSize: FONT_SIZE
            }} 
			stroke={theme.primary} fill="none" strokeWidth={LINE_WIDTH}
		> 
			<g transform={`translate(${-targetScaled.x || 0} ${center - targetScaled.y - /*posSign **/ DEVICE_HEIGHT / 4 ||0})`}>
                
                <g strokeWidth={LINE_WIDTH/3}>
                    {circles}
                    {angles} 
                    {dots}
                </g>
                
                {currentScaled && 
                    <g>
                        <circle cx={currentScaled.x} cy={currentScaled.y} r={circleSize}/>
						<circle cx={currentScaled.x} cy={currentScaled.y} r={circleSize - LINE_WIDTH / 2} stroke={theme.background} strokeWidth={1} />
						<circle cx={currentScaled.x} cy={currentScaled.y} r={circleSize + LINE_WIDTH / 2} stroke={theme.background} strokeWidth={1} />
                        <line x1="0" y1={center} x2={currentLineEnding.x} y2={currentLineEnding.y}/>
                    </g>                  
                }

				{targetScaled &&
					<Cross {...targetScaled} {...crossSize} fill={theme.background} stroke={theme.primary} strokeWidth={2} />
				}
            </g>
            
            <NumberDisplays height={DEVICE_HEIGHT} width={DEVICE_WIDTH} theme={theme}>
				{isFinite(targetSow) && <Display value={targetSow.toFixed(1)} text="Target Speed kn" place="left" textPlace="bottom" border={3} />}
				{isFinite(targetWowa) && <Display value={Math.abs(targetWowa).toFixed()} text={`Target WWA \u00B0`} textPlace="bottom" border={3} />}
				{/*isFinite(efficiency) && <Display value={efficiency.toFixed()} text="Efficiency %" textPlace="bottom"/>*/}
				{isFinite(sow) && <Display value={sow.toFixed(1)} text="Speed kn" place="left" />}
				{isFinite(wowa) && <Display value={Math.abs(wowa).toFixed()} text={`WWA \u00B0`} />}
            </NumberDisplays>
        </svg>              
    )              
}