import { FONT_SIZE, DEVICE_HEIGHT, DEVICE_WIDTH, LINE_WIDTH } from '../settings'
import { Display, NumberDisplays } from '../ViewComponents/NumberDisplays'
import Cross from '../ViewComponents/Cross'
import { rx, ry, calcPosFromDistAngle } from "../ViewComponents/tools"
import { isNumber } from '../tools'

export default function Helming({ targetHeel, targetPitch, heel, pitch, history, theme }) {
	if (!targetHeel && !targetPitch) return null
	const center = DEVICE_HEIGHT / 2
	const scale = DEVICE_HEIGHT * 5
	const crossSize = { size: 70, thickness: 8 }
	const circleSize = 35
	const dotSize = 25

	const calcScaledPos = (heel, pitch = 0) => {
		return calcPosFromDistAngle(1 + pitch / 100, heel, scale, { x: 0, y: center })
	}

	const targetScaled = targetHeel && calcScaledPos(targetHeel, targetPitch)
	const currentScaled = heel && calcScaledPos(heel, pitch)
	const currentLineEnding = currentScaled && {
		x: rx(circleSize, heel + 90, currentScaled.x),
		y: ry(circleSize, heel + 90, currentScaled.y),
	}

	let circle = <circle cx="0" cy={center} r={scale} />

	let angles = []
	for (let i = 0; i < 360; i += 10) {
		const lineEnding = calcScaledPos(i)
		angles.push(
			<line x1="0" y1={center} x2={lineEnding.x} y2={lineEnding.y} key={i} />
		)
	}

	let dots = []
	for (let i = 0; i < history.length; i++) {
		let circle = calcScaledPos(history[i].heel, history[i].pitch)
		dots.push(
			<circle
				cx={circle.x} cy={circle.y} r={dotSize}
				opacity={i / (history.length - 1)} fill={theme.primary}
				key={history[i].key}
			/>
		)
	}

	return (
		<svg
			viewBox={`${-DEVICE_WIDTH / 2} 0 ${DEVICE_WIDTH} ${DEVICE_HEIGHT}`}
			style={{
				backgroundColor: theme.background,
				height: "100%",
				width: "100%",
				color: theme.primary,
				fontSize: FONT_SIZE
			}}
			stroke={theme.primary} fill="none" strokeWidth={LINE_WIDTH}
		>
			<g transform={`translate(${-targetScaled.x || 0} ${center - targetScaled.y || 0})`}>

				<g strokeWidth={LINE_WIDTH / 3}>
					{circle}
					{angles}
					{dots}
				</g>

				{currentScaled &&
					<g>
						<circle cx={currentScaled.x} cy={currentScaled.y} r={circleSize} />
						<circle cx={currentScaled.x} cy={currentScaled.y} r={circleSize - LINE_WIDTH / 2} stroke={theme.background} strokeWidth={2} />
						<circle cx={currentScaled.x} cy={currentScaled.y} r={circleSize + LINE_WIDTH / 2} stroke={theme.background} strokeWidth={2} />
						<line x1="0" y1={center} x2={currentLineEnding.x} y2={currentLineEnding.y} />
					</g>
				}

				{targetScaled &&
					<Cross {...targetScaled} {...crossSize} fill={theme.background} stroke={theme.primary} strokeWidth={2} />
				}
			</g>

			<NumberDisplays height={DEVICE_HEIGHT} width={DEVICE_WIDTH} theme={theme}>
				{isNumber(heel) && <Display value={heel.toFixed()} text={`Heel \u00B0`} place="left" textPlace="bottom" />}
				{isNumber(pitch) && <Display value={pitch.toFixed()} text={`Pitch \u00B0`} textPlace="bottom" />}
				{/*isNumber(sow) && <Display value={sow.toFixed(1)} text="Speed kn" place="left" />}
				{isNumber(wowa) && <Display value={Math.abs(wowa).toFixed()} text={`WOWA \u00B0`} />*/}
			</NumberDisplays>
		</svg>
	)
}