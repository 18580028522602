import Boat from "../ViewComponents/Boat"
import { angle180, calcDistance, calcShift } from '../location'
import { LINE_WIDTH, DEVICE_HEIGHT, DEVICE_WIDTH } from '../settings'
import { calcPosFromGeogr, calcPosFromDistAngle, calcPosFromLenAngle } from "../ViewComponents/tools"
import { calcDestination, kn2ms } from '../location'

export default function Assist({ distanceToStart, startLineDirection, timeToStart, boatLength = 10, heading, startVector, pin, rcb, position, theme }) {
	
	const startBoxTimeSpan = 30
	const laylineLength = startVector.length ? kn2ms(startVector[0]) * 30 : boatLength * 6 * 1.44
	const startDirection = startVector.length ? startVector[1] : startLineDirection + 45
	const laylineAngle = calcShift(startLineDirection, startDirection) + 90
	const startBoxCornerPin = calcDestination(pin, startDirection, laylineLength)
	const boxWidth = calcDistance(startBoxCornerPin, {...rcb, gate: pin}, true)
	const withinBox = distanceToStart >= 0 && distanceToStart <= boxWidth
	
	const pad = 6
	const distanceToShow = pad*2 + (distanceToStart < 0 
		? boxWidth - distanceToStart
		: Math.max(boxWidth, distanceToStart))
	const scale = DEVICE_HEIGHT / distanceToShow
	
	if (!isFinite(heading)) heading = startLineDirection + 90
	const scaledBoatLen = Math.max(boatLength * scale, 40)
	const boatAngle = angle180(heading - startLineDirection - 90)
	//const boatPos = -ry(scaledBoatLen / 2, boatAngle - 90)

	const screenDirection = startLineDirection+90
	const pinScaled = calcPosFromGeogr(pin, position, scale, screenDirection)
	const rcbScaled = calcPosFromGeogr(rcb, position, scale, screenDirection)
	const pinBoxCorner = calcPosFromDistAngle(laylineLength, laylineAngle, scale, pinScaled)
	const remainingLength = startVector.length
		? laylineLength / startBoxTimeSpan * Math.min(timeToStart >= 0 ? timeToStart / 1000: 0, 30)
		: laylineLength
	const runningLineStart = calcPosFromDistAngle(remainingLength, laylineAngle, scale, pinScaled)
	// const runningLineStartDuplicate = calcPosFromLenAngle(6, laylineAngle - 180, runningLineStart)
	const oneThird = calcPosFromDistAngle(laylineLength/3, laylineAngle, scale, pinScaled)
	const twoThirds = calcPosFromDistAngle(laylineLength/3*2, laylineAngle, scale, pinScaled)
	const startLineLength = calcDistance(rcb, pin) * scale

	let shift = pad * scale
	if (distanceToStart > 0) shift += distanceToStart * scale

	const horizontalLine = (y, x, length, stroke) => <line x1={x} y1={y} x2={x+length} y2={y} strokeDasharray={stroke ? "8 8" : "1 0"} />
	const labelStyle = { lineHeight: "initial", background: theme.background, fontWeight: "bold" }

	return (
		<g transform={`translate(0, ${shift})`}>	
			<g style={{fontSize: "0.3em"}}>
				{startVector.length ?
					<g strokeWidth={LINE_WIDTH / 3}>
						<path fill={theme.secondary} d={`
							M ${rcbScaled.x} ${rcbScaled.y}
							h ${-startLineLength}
							L ${runningLineStart.x} ${runningLineStart.y}
							h ${startLineLength}
							z
						`}/>
						{/* {horizontalLine(runningLineStart.y, runningLineStart.x, startLineLength)} */}
						{/* {horizontalLine(runningLineStartDuplicate.y, runningLineStartDuplicate.x, startLineLength)}	 */}
					</g>
					: 
					<g>{horizontalLine(runningLineStart.y, runningLineStart.x, startLineLength, true)}</g>
				}
				{horizontalLine(oneThird.y, oneThird.x, startLineLength - 4, true)}
				{horizontalLine(twoThirds.y, twoThirds.x, startLineLength - 4, true)}
				{withinBox &&
					<g>
						<foreignObject x={-DEVICE_WIDTH / 4} y={oneThird.y - 20} width="70" height="40">
							<label style={labelStyle}>{startVector.length ? "10s" : "2BL"}</label>
						</foreignObject>
						<foreignObject x={-DEVICE_WIDTH / 4} y={twoThirds.y - 20} width="70" height="40">
							<label style={labelStyle}>{startVector.length ? "20s" : "4BL"}</label>
						</foreignObject>
					</g>
				}
				<line x1={pinScaled.x} y1={pinScaled.y} x2={pinBoxCorner.x} y2={pinBoxCorner.y}/>
				<line x1={rcbScaled.x} y1={rcbScaled.y} x2={pinBoxCorner.x+startLineLength} y2={pinBoxCorner.y}/>					
			</g>

			{distanceToStart && 
				<g transform={`rotate(${boatAngle || 0})`}>
					<g transform={`translate(0, ${scaledBoatLen / 2})`}>
						<Boat length={scaledBoatLen} />
					</g>
				</g>
			}
			
			{horizontalLine(pinScaled.y, pinScaled.x, startLineLength)}
			<circle cx={pinScaled.x} cy={pinScaled.y} r="10" />
			<circle cx={rcbScaled.x} cy={rcbScaled.y} r="10" />
		</g>
	)
}
