import BigTriangle from "../ViewComponents/BigTriangle"
import { rx, ry, calcPosFromGeogr } from "../ViewComponents/tools"
import { calcDistance, calcDirection, calcDestination } from "../location"
// import WindArrow from "../ViewComponents/WindArrow"
// import CurrentArrow from "../ViewComponents/CurrentArrow"

export default function Racing({ wp1, wp2, position, cog, rhumbDirection, laylines, segments, theme, height, rounding, boatLength, segment, currentDirection, twd }) {
        
    let mediumMode = !wp2.lat || (segment && (!segment.initialCourse || segment.end < segment.start + 30))

	let boatWp2 = wp2 && calcDistance(position, wp2)   

    let scale
    if (rounding) {
        scale = (height)/(boatWp2 + 10*boatLength)        
    } else {
        if (mediumMode) {
            scale = height/(boatLength*50)  
        } else {
            let distance = wp2.lat ? calcDistance(wp1, wp2) : calcDistance(wp1, position)*1.5
            scale = height/distance
        }        
    }  

    let boatLenScaled = boatLength*scale
    if (boatLenScaled < 60) boatLenScaled = 60 

    let bindPoint, bindPointScaled
    if (rounding) {
        bindPoint = calcDestination(position, calcDirection(position, wp2), calcDistance(position, wp2)/3)
        bindPointScaled = {x: 0, y: height/2}
    } else {
        if (mediumMode) {
            bindPoint = position
            bindPointScaled = {x: 0, y: height/2}
        } else {
            bindPoint = wp1
            bindPointScaled = {x: 0, y: height}
        }
        
    }
        
    let calcScaledPosition = (pos) => {                
        return calcPosFromGeogr(pos, bindPoint, scale, rhumbDirection, bindPointScaled)
    }      
         
    const wp2Scaled = calcScaledPosition(wp2)
    const wp1Scaled = calcScaledPosition(wp1)
    const boatAngle = cog-rhumbDirection
    const boatPos = calcScaledPosition(position, wp1, rhumbDirection )
    
    // const boatCenter = {
    //     x: rx(boatLenScaled/2, boatAngle+90, boatPos.x), 
    //     y: ry(boatLenScaled/2, boatAngle+90, boatPos.y)
    // }
    // let currentAngle = (currentDirection - rhumbDirection) || 0
    // let windAngle = (180-(rhumbDirection-twd)) || 0
    
    let track = segments && segments.map((segment) => 
        segment.reduce((lines, dot, i) => {
            if (segment[i+1]) {
                let start = calcScaledPosition(dot)
                let end = calcScaledPosition(segment[i+1])
                lines.push(<line x1={start.x} y1={start.y} x2={end.x} y2={end.y} key={i}/>)
            }
            return lines                        
        }, [])
    )

    // Degrees marks
    // let degreesMarks = () => {
    //     let marks = []
    //     let bearing = calcDirection(position, wp2)
    //     let rhumbBearingAngle = bearing - rhumbDirection
    //     let boatSideDist = Math.cos(toRadians(rhumbBearingAngle)) * boatWp2
    //     let step = cog - bearing < 0 ? -10 : 10
	// 	let angle = rhumbBearingAngle + step
    //     for (let mark = 0; Math.abs(mark) < viewWidth/2 && marks.length < 9; angle += step) {
    //         let boatMarkDist = boatSideDist / Math.cos(toRadians(angle))
    //         mark = boatPos.x + rx(boatMarkDist * scale, angle-90)
    //         marks.push(mark)
    //     }
        
    //     return marks
    // }
  
    return (
        <g>
            {!rounding && <circle cx={wp1Scaled.x} cy={wp1Scaled.y} r="10" />}
            {wp2.lat && <circle cx={wp2Scaled.x} cy={wp2Scaled.y} r="10"/>}

            <line strokeDasharray="2 9" 
                x1={wp1Scaled.x} y1={wp1Scaled.y} 
                x2={wp2Scaled.x||wp1Scaled.x} y2={wp2Scaled.y||0} 
            />
            
            {rounding && <circle cx={wp2Scaled.x} cy={wp2Scaled.y} r={boatLenScaled*3} fill="transparent"/>}
            
            {wp2.lat && laylines && laylines.map((direction, i) => 
                <line x1={wp2Scaled.x} y1={wp2Scaled.y} 
                    x2={wp2Scaled.x} y2={height/*boatWp2*scale*1.2*/}
                    transform={`rotate(${direction - rhumbDirection + 180}, ${wp2Scaled.x}, ${wp2Scaled.y})`} 
                    key={i}
                />
            )}            
            
            {track}

            <g transform={`translate(${boatPos.x} ${boatPos.y}) rotate(${boatAngle||0})`} >
                <BigTriangle height={boatLenScaled}/>
                <line x1="0" y1="0" x2="0" y2={-height} strokeDasharray="13 4"/>
            </g> 
            
			{/*currentDirection && 
				<g transform={`rotate(${currentDirection - rhumbDirection || 0} ${boatPos.x} ${boatPos.y})`}>
					<g transform={`translate(${boatPos.x} ${boatPos.y + 50})`}>
							<CurrentArrow fill={theme.secondary} />
					</g>
				</g>
			*/}

			{/*twd &&
				<g transform={`rotate(${twd + 180 - rhumbDirection || 0} ${boatPos.x} ${boatPos.y})`}>
					<g transform={`translate(${boatPos.x} ${boatPos.y - 40})`}>
						<WindArrow />
					</g>
				</g>
			*/}

            {/*wp2.lat && degreesMarks().map((mark, i) => <g transform={`translate(${mark} 7) rotate(180)`} key={i}><SmallTriangle /></g>)*/}
        </g>        
    )           
}