import { useEffect } from 'react'
import { useStore } from './store'
import { isNumber } from './tools'

export default function useUserId(email) {
	let [userId, setUserId] = useStore('userId')
	useEffect(() => {
		if (isNumber(email)) {
			setUserId(parseInt(email))
		} else {
			console.log('GET userId', email)
			fetch('https://raceqs.com/rest/user?user_email=' + email)
				.then(x => x.json())
				.then(x => {
					x = x[0] && x[0].id
					if (x) console.log('GET userId OK', x)
					setUserId(x)
				})
				.catch(e => console.log('GET userId ERR', e))
		}
	}, [email, setUserId])
	return userId
}