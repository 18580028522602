import { toRadians } from "../location"
import { calcDistance, calcDirection } from "../location"

// Math functions are calculating numbers that are being converted to strings. 
// JavaScript will convert extremely small numbers to scientific notation.
// function is used to return 0 for small numbers.
var num = function(n) {
    return (Math.abs(n) < 0.0000001) ? 0 : n 
  }

// calculate the x and y coordinates based on the radius and angle
export function rx(r, a, c=0) {
    return num(c + r * Math.cos(toRadians(a)))
}

export function ry(r, a, c=0) {
    return num(c + r * Math.sin(toRadians(a)))
}

// Text placed 
export function Text({radius, angle, offset, text}) {
    return (
        <text x={rx(radius+offset, angle-90)} y={ry(radius+offset, angle-90)} text-anchor="middle" style={{fontSize: 10}}>{text}</text>
    )
}

export function formatTime(ms, mode="min") {  
	const inSeconds = Math.abs(Math.ceil(ms / 1e3))
	let seconds = inSeconds % 60
    let minutes = (inSeconds - seconds) / 60  
    let sign = ms < 0 ? '-' : '' 
    if (seconds.toString().length < 2) seconds = '0' + seconds
    if (mode === "hour") {
        let hours = Math.floor(minutes / 60)
        minutes -= hours * 60
        if (minutes.toString().length < 2) minutes = '0' + minutes
        return sign + hours + ':' + minutes + ':' + seconds
    } else {        
        return sign + minutes + ':' + seconds
    }
}

export function isBetween (n, a, b) {
    return (n >= a && n <= b)
}

export function calcPosFromDistAngle(distance, angle, scale, center={x: 0, y: 0}) { 
    return {x: rx(distance*scale, angle-90, center.x), y: ry(distance*scale, angle-90, center.y)}
}

export function calcPosFromLenAngle(length, angle, center={x: 0, y: 0}) { 
    return {x: rx(length, angle-90, center.x), y: ry(length, angle-90, center.y)}
}

export function calcPosFromGeogr(pos, geogrCenter, scale, screenDirection=0, scaledCenter={x: 0, y: 0}) {    
    const distance = calcDistance(geogrCenter, pos)
    const angle = calcDirection(geogrCenter, pos) - screenDirection
    return calcPosFromDistAngle(distance, angle, scale, scaledCenter)
}
