function ThickArrow({ fill="transparent", length=100, width=50 }) {
    const head = length/3
    const wing = width/3  

    return (
        <g fill={fill} strokeWidth="4">
            <path d={`
                M 0 0
                l ${width/2+wing} ${-head}
                h ${-wing}
                v ${-(length-head)}
                h ${-width}
                v ${length-head}
                h ${-wing}
                L 1 0
            `} />
        </g>
    )
}

export default ThickArrow