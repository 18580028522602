function WindArrow({ length=120 }) {
    const head = length/6
    const feather = length/3
    const pad = length/8

    return (
        <g strokeWidth="4">
            <polygon points={`0,0 ${-head/2},${-head} ${head/2},${-head}`}/>
            <line x1="0" y1="0" x2="0" y2={-length}/>
            <line x1="0" y1={-length+1} x2={feather} y2={-length-pad}/>
            <line x1="0" y1={-length+pad} x2={feather} y2={-length}/>
            <line x1="0" y1={-length+pad*2} x2={feather/1.4} y2={-length+pad*1.3}/>
        </g>
    )
}

export default WindArrow