// @ts-check
import { all, forEach, range, zipWith, splitAt } from 'rambda'
import { memo, pass, pass2, round, interpolate, isNumber, signum } from './tools'
import { calcMiddle, calcBisector, calcDestination, calcShift, calcCross, calcDirection, angle360, calcDistance, calcSpeed, angularArithmetic, angularArithmetic180, toRadians, toDegrees, calcWind, angle180, posKind, calcPos, avgAngle, posTack, calcProjection, standardArithmetic, lowPass, kn2ms, addPolarVectors, posSign } from './location'
import { MAX_JIBING_ANGLE, MIN_JIBING_ANGLE, MAX_TACKING_ANGLE, MIN_TACKING_ANGLE, TINY_WINDOW, MIN_MANEUVER_SHIFT, LARGE_SHIFT, SMALL_WINDOW, SMALL_SHIFT, MEDIUM_WINDOW, TINY_SPAN, MIN } from './settings'
// import { KalmanFilter } from 'kalman-filter'

/**
 * @typedef {object} Track
 * @typedef {number} Index
 */

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getCalibration(track, i) {
	if (track[i].awa === undefined && track[i].aws === undefined && track[i].sow === undefined) return {}
	return {
		// awa: -8,
		// heading: 5,
		// roll: -90,
		// pitch: -12,
	}
}

/**
 * @param {function} fn
 * @param {number|function} [factor]
 * @return {function}
 */
export let getLowPass = (fn, factor) => (track, i) => {
	let key = 'lowPass' + (fn.name || fn) + factor
	return memo(track[i], key, () => {
		if (i < 1) return
		let x = fn(track, i, i - 1)
		if (x === undefined) x = NaN
		if (i === 1) return x
		let j = i - 1
		for (; j > 1; j--) {
			if (key in track[j]) break
		}
		let last
		for (; j < i; j++) {
			last = getLowPass(fn, factor)(track, j)
		}
		if (!Number.isFinite(last)) return x
		if (!Number.isFinite(x)) return last
		let f = typeof(factor) === 'function' ? factor(track, i) : factor
		return lowPass(f, last, x, fn.arithmetic)
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getManeuvering(track, i, j) {
	if (!j || j < 1) j = Math.max(1, i - 10)
	let s = calcShift(getHeading(track, j), getHeading(track, i))
	return Math.min(1, Math.abs(s) / 50)
	// return memo(track[i], 'maneuvering', () => {
	// let n = 5
	// if (i < n + 2) return
	// let j = i - n
	// let h = getHeading(track, j)
	// let w = getAwa(track, j)
	// let xs = track.slice(0, n)
	// let hs = xs.map((_, ix) => calcShift(h, getHeading(track, i - ix, i - ix - 1)))
	// let ws = xs.map((_, ix) => -calcShift(w, getAwa(track, i - ix, i - ix - 1)))
	// xs = hs.concat(ws)
	// let rate = xs.reduce((a, b) => a + signum(b), 0) / xs.length
	// return rate * rate
	// })
}

/**
 * @param {number} [a] - from
 * @param {number} [b] - till
 * @param {number} [w] - window
 * @return {function}
 */
let groovingFactor = (a = 0, b = 1, w = 10) => (track, i) => interpolate(0, 1, getManeuvering(track, i, i - w), b, a)

let lpHeading = getLowPass(getHeading, 1)
let lpAwa = getLowPass(getAwa, 0.5)
let lpAws = getLowPass(getAws, 0.5)
let lpSow = getLowPass(getSow, 0.5)
let lpSpeed = getLowPass(getSpeed, 0.1)
let lpDrift = getLowPass(getDrift, groovingFactor(0.2, 0.05, 20))
let lpTwd = getLowPass((track, i, j) => getWind(track, i, j).twd, groovingFactor(0, 0.1))
let lpTws = getLowPass((track, i, j) => getWind(track, i, j).tws, groovingFactor(0, 0.1))
let lpCurrentDirection = getLowPass((track, i, j) => getCurrent(track, i, j).direction, groovingFactor(0, 0.2, 30))
// let lpCurrentDirection = getLowPass((track, i, j) => getCurrent(track, i, j).direction, 1)
let lpCurrentSpeed = getLowPass((track, i, j) => getCurrent(track, i, j).speed, groovingFactor(0, 0.2, 30))
// let lpCurrentSpeed = getLowPass((track, i, j) => getCurrent(track, i, j).speed, 1)

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getLat(track, i, j) {
	if (j == null || j >= i - 1) return track[i].lat
	return getAvg(track, i, j, getLat)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getLon(track, i, j) {
	if (j == null || j >= i - 1) return track[i].lon
	return getAvg(track, i, j, getLon)
}

/**
 * @param {Track} track
 * @param {Index} [i]
 * @param {Index} [j]
 * @return {number}
 */
export function getSpeed(track, i, j) {
	if (i == null) return track.interpolated.speed
	if (j == null) return lpSpeed(track, i)
	let speed = calcSpeed(getDistance(track, i, j), track[i].time - track[j].time)
	return speed < 50 && speed
}

/**
 * @param {Track} track
 * @param {Index} [i]
 * @param {Index} [j]
 * @return {number}
 */
export function getCourse(track, i, j) {
	if (i == null) return track.interpolated.course
	if (j == null) return angle360(getHeading(track, i) + getDrift(track, i))
	while (j >= 0) {
		var x = calcDirection(track[j], track[i])
		if (x) return x
		j--
	}
	return 0
}
getCourse.arithmetic = angularArithmetic

/**
 * @param {Track} track
 * @param {Index} [i]
 * @param {Index} [j]
 * @return {number}
 */
export function getHeading(track, i, j) {
	if (i == null) return track.interpolated.heading
	if (j == null) return lpHeading(track, i)
	if (j >= i - 1) {
		return (
			isNumber(track[i].heading)
				? angle360(track[i].heading + (getCalibration(track, i).heading || 0))
				: getCourse(track, i, i - 1)
		)
	}
	return getAvg(track, i, j, getHeading)
}
getHeading.arithmetic = angularArithmetic

/**
 * @param {Track} track
 * @param {Index} [i]
 * @param {Index} [j]
 * @return {number}
 */
export function getDrift(track, i, j) {
	if (i == null) return track.interpolated.drift
	if (j == null) return lpDrift(track, i)
	if (j >= i - 1) {
		return calcShift(getHeading(track, i, j), getCourse(track, i, j))
	}
	return getAvg(track, i, j, getDrift)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getSow(track, i, j) {
	if (j == null) return lpSow(track, i)
	if (j >= i - 1) {
		return track[i].sow || getSpeed(track, i, j)
	}
	return getAvg(track, i, j, getSow)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getAwa(track, i, j) {
	if (j == null) return lpAwa(track, i)
	if (j >= i - 1) {
		return track[i].awa + (getCalibration(track, i).awa || 0)
	}
	return getAvg(track, i, j, getAwa)
}
getAwa.arithmetic = angularArithmetic180

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getAws(track, i, j) {
	if (j == null) return lpAws(track, i)
	if (j >= i - 1) return track[i].aws
	return getAvg(track, i, j, getAws)
}

/**
 * @param {Track} track
 * @param {Index} [i]
 * @param {Index} [j]
 * @return {number}
 */
export function getPitch(track, i, j) {
	if (i == null) return track.interpolated.pitch
	if (!isNumber(track[i].pitch)) return null
	let { pitch = 0 } = getCalibration(track, i)
	return track[i].pitch + pitch
}

/**
 * @param {Track} track
 * @param {Index} [i]
 * @param {Index} [j]
 * @return {number}
 */
export function getRoll(track, i, j) {
	if (!isNumber(track[i].roll)) return null
	let { roll = 0 } = getCalibration(track, i)
	return track[i].roll + roll
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @return {number}
 */
export function getDistance2(track, i, j) {
	return memo(track[i], 'distance_' + j, () => {
		if (j == null) j = i - 1
		return calcDistance(track[j], track[i])
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getDistance(track, i, j) {
	return memo(track[i], 'travelDistance_' + j, () => {
		if (j == null) j = i - 1
		var s = 0
		for (var k = i; k > j;) {
			var l = Math.max(j, getWindow(track, k, SMALL_WINDOW) - 1)
			s += calcDistance(track[k], track[l])
			k = l
		}
		return s
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {object}
 */
export function getWind(track, i, j) {
	return memo(track[i], 'wind' + j, () => {
		if (j >= i - 1) {
			let awa = getAwa(track, i)
			let aws = getAws(track, i)
			let heading = getHeading(track, i)
			let cog = getCourse(track, i)
			let sog = getSpeed(track, i)
			return calcWind({ awa, aws, heading, cog, sog })
		}
		if (j == null) return {
			twd: lpTwd(track, i),
			tws: lpTws(track, i),
		}
		return {
			twd: getAvg(track, i, j, (track, i) => getWind(track, i).twd, angularArithmetic),
			tws: getAvg(track, i, j, (track, i) => getWind(track, i).tws),
		}
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getTwa(track, i, j) {
	let { twd } = getWind(track, i, j)
	let heading = getHeading(track, i, j)
	return calcShift(heading, twd + 180)
}

export function calcCurrent({ sog, sow, drift, heading }) {
	let x = sog * Math.sin(toRadians(drift))
	let y = sog * Math.cos(toRadians(drift)) - sow
	let speed = Math.sqrt(x * x + y * y)
	let angle = angle180(90 - toDegrees(Math.atan2(y, x)) + 180)
	let direction = angle360(heading + angle + 180)
	return { x, y, angle, direction, speed }
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {object}
 */
export function getCurrent(track, i, j) {
	return memo(track[i], 'current' + j, () => {
		if (j == null) return {
			direction: lpCurrentDirection(track, i),
			speed: lpCurrentSpeed(track, i),
		}
		// if (j >= i - 1) {
			j = null //i - 1 //Math.max(1, i - 20)
			let awa = getAwa(track, i)
			let leeway = signum(awa) * interpolate(60, 160, Math.abs(awa), 5, 0)
			return calcCurrent({
				sog: getSpeed(track, i, j),
				sow: getSow(track, i, j),
				drift: getDrift(track, i, j) + leeway,
				heading: getHeading(track, i, j),
			})
		// }
		// return {
		// 	// angle: getAvg(track, i, j, (track, i) => getCurrent(track, i).angle, angularArithmetic180),
		// 	direction: getAvg(track, i, j, (track, i) => getCurrent(track, i).direction, angularArithmetic),
		// 	speed: getAvg(track, i, j, (track, i) => getCurrent(track, i).speed),
		// }
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getTackingAngle(track, i, j) {
	return getAngles('tack')(track, i, j).avg || 90
}

/**
 * @param {string} kind
 * @return {function}
 */
export let getAngles = kind => (track, i, j) => {
	if (!j) j = 1
	let s1 = getSegment(track, j)
	let s2 = getSegment(track, i)
	let ss = track.segments
	let sum = 0
	let cnt = 0
	let min = Infinity
	let max = -Infinity
	for (let k = s1.index; k <= s2.index; k++) {
		let s = ss[k]
		if (s[kind]) {
			sum += s.shift
			cnt++
			min = Math.min(min, s.shift)
			max = Math.max(max, s.shift)
		}
	}
	if (!cnt) return {}
	return { 
		min, 
		avg: sum / cnt, 
		max,
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number[]}
 */
export function getLaylineDirections(track, i) {
	let sign = getLegPosSign(track, i)
	if (!sign) return []
	let angles = getAngles(sign === 1 ? 'tack' : 'jibe')(track, i, 0)
	if (!angles.avg) return []
	let ss = track.segments
	let groove
	for (let i = ss.length - 1; i > 1; i--) {
		let s = ss[i]
		if (s.end < track.start) break
		if (s.straight && getLegPosSign(track, s.start) === sign) {
			groove = s
			break
		}
	}
	if (!groove) return []
	let ll = calcDirection(track[groove.end - 5], track[groove.start - 20])
	let kind = sign * signum(getAwa(track, groove.end))
	// return [ll + kind * angles.min, ll + kind * angles.avg, ll + kind * angles.max, ll]
	return [ll + kind * angles.avg, ll]
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getLayline(track, i) {
	let wp = getWp(track, i)
	if (!wp) return
	let ds = getLaylineDirections(track, i)
	if (!ds.length) return
	return { 
		lat: wp.lat, 
		lon: wp.lon, 
		gate: calcDestination(wp, ds[0], 1e6) 
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {object} [ll]
 * @return {number}
 */
export function getDistanceToLayline(track, i, ll) {
	if (!ll) ll = getLayline(track, i)
	if (!ll) return
	return calcDistance(track[i], ll, true)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
function getLastManeuver(track, i) {
	var segments = track.segments
	for (i = getSegment(track, i).index; i > 0; i--) {
		var segment = segments[i]
		if (segment.maneuver) {
			return segment
		}
	}
	return segments[0]
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getTimeToLayline(track, i, j) {
	var m = getLastManeuver(track, i)
	if (m.end >= i) return
	if (j == null) j = Math.max(getWindow(track, i, 60), getWindow(track, m.end, TINY_WINDOW))
	var ll = getLayline(track, i)
	var d1 = getDistanceToLayline(track, i, ll)
	var d2 = getDistanceToLayline(track, j, ll)
	return (track[i].time - track[j].time) / (d2 - d1 + 1e-9) * d1
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {object} [wp]
 * @param {number} [direction]
 * @return {number}
 */
export function getEffectiveDtm(track, i, wp, direction) {
	var p = getNose(track, i)
	if (!wp) wp = getWp(track, i)
	if (!wp) return
	if (direction == null) direction = getVmgDirection(track, i)
	if (direction == null) return
	return calcProjection(p, wp, direction)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {object} [wp]
 * @param {number} [wind]
 * @return {number}
 */
export function getWindDtm(track, i, wp, wind) {
	if (wind == null) wind = getCombinedWind(track, i)
	if (wind == null) return
	return getEffectiveDtm(track, i, wp, wind)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getTimeToMark(track, i, j) {
	if (!j) j = getWindow(track, i, 60)
	switch (getLegPosSign(track, i)) {
		case 1:
			return Math.abs(getWindDtm(track, i) / kn2ms(getWindVmg(track, i, j) + 1e-9) * 1e3)
		case -1:
			return Math.abs(getWindDtm(track, i) / kn2ms(getWindVmg(track, i, j) + 1e-9) * 1e3)
		default:
			return Math.abs(getDtm(track, i) / kn2ms(getMarkVmg(track, i, j) + 1e-9) * 1e3)
	}
}

export function resetCombinedWind(ctx) {
	let { winds, route, start, rcb, pin } = ctx
	winds.splice(0)
	var r = route[0]
	// ctx.initialWind = nvl(r && r.windDirection, start && start.wind, rcb && pin && calcDirection(rcb, pin) - 90)
	ctx.initialWind = rcb && pin && calcDirection(rcb, pin) - 90
	winds.push({ time: ctx.time.start - 1, wind: ctx.initialWind })
	if (ctx.initialWind != null) winds.push(winds[0])
	winds.current = 0
}

/**
 * @param {number=} time
 */
export function calc(ctx, time) {
	if (time && time === calc.time) return
	calc.time = time
	
	let { route, track, rival, fleet, tracks } = ctx

	if (!time) {
		calcFleet()
		resetCombinedWind(ctx)

		route.distance = 0
		for (var k = 1; k < route.length; k++) {
			route.distance += calcDistance(route[k - 1].wp, route[k].wp)
		}
		return
	}

	if (track && global.i !== track.current) {
		console.error('!>>> i != track.current <<<!')
		// debugger
		global.i = track.current
	}
	fleet.forEach(function (track) {
		for (var s = getNextSegment(track, track.current); s; s = getNextSegment(track, s.start)) {
			if (getSegmentWind(track, s.start) != null) break
		}
	})
	forEach(function (track) {
		if (!track) return
		passTime(track, time)
		getLeg(track, track.current)
		var i1 = track.current
		var i2 = i1 + 1
		var p1 = track[i1]
		var p2 = track[i2]
		if (!p2 || p1.time > time || p2.time - p1.time > 600e3 || getSpeed(track, i2, i1) > 100) {
			i2 = i1
			p2 = p1
		}
		var wind = interWind(track, time)
		track.interpolated = {
			time: time
			, lat: interpolate(p1.time, p2.time, time, p1.lat, p2.lat)
			, lon: interpolate(p1.time, p2.time, time, p1.lon, p2.lon)
			, speed: interSpeed(track, time)
			, course: interCourse(track, time)
			, heading: interHeading(track, time)
			, drift: interDrift(track, time)
			, heel: interHeel(track, time)
			, pitch: interPitch(track, time)
			, sow: interSow(track, time)
			, awa: interAwa(track, time)
			, aws: interAws(track, time)
			, wind: wind
			, pos: getPos(track, i1, wind)
			, vmgDirection: interVmgDirection(track, time)
			, vmg: interVmg(track, time)
		}
	}, tracks || fleet)
	let back = /*BUOYS &&*/ track.current < global.i
	global.i = track && track.current
	global.j = rival && rival.current
	if (back) refresh()
}

export function resetRoute(route) {
	route.forEach(function (leg) {
		delete leg.gate
		delete leg.wind
	})
}

export function resetTrack(track) {
	track.segments = []
	track.legs = []
	track.start = undefined
	track.finish = undefined
	track.forEach(function (p) {
		delete p.segment
		delete p.leg
	})
	track.rest && track.rest.forEach(function (p) {
		delete p.segment
		delete p.leg
	})
}

export function reset() {
	let { route, tracks, track, time } = global
	resetRoute(route)
	forEach(resetTrack, tracks)
	// candidates = []
	// track.ctx = global
	calc(global)
	calc(global, time.now)
}

export function refresh() {
	reset()
	global.redraw && global.redraw()
}

export function calcFleet() {
	global.fleet = []
	let { fleet, tracks, start, regatta } = global
	forEach(function (track) {
		getLeg(track, 1)
		var journey = track.boat.journey
		var divisionId = journey && journey.explicitDivision && journey.divisionId
		if (start && divisionId && Number(divisionId) === Number(start.divisionId) && track.start == null) {
			fleet.push(track)
		}
	}, tracks)

	if (regatta && regatta.virtual) {
		forEach(function (track) {
			var time = getStartTime(track) || track[0]._time || track[0].time
			track.forEach(function (p) {
				if (!p._time) p._time = p.time
				p.time = p._time - time
			})
		}, tracks)
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getSegmentWind(track, i) {
	var segment = getSegment(track, i)
	return memo(segment, 'wind', function () {
		let { route } = track.ctx
		// if (initialWind == null) return null
		if (segment.start <= (track.start || Infinity)) {
			return null
		}

		var segments = track.segments
		for (var j = segment.index - 1; j > 0 && segments[j].wind === undefined; j--);
		for (; j < segment.index; j++) {
			getSegmentWind(track, segments[j].start)
		}

		var leg = getLeg(track, i)
		if (!leg) return null
		if (segment.start <= leg.end && segment.end >= leg.end) {
			var r = route[leg.index]
			if (r && r.windDirection != null) {
				let wind = r.windDirection
				addWind(track, { time: track[segment.start].time, wind: wind, weight: 1, reason: 'leg', leg: r })
				return wind
			}
		}

		if (segment.straight && !segments[segment.index - 1].maneuver /*&& segment.end - segment.start > 5*/) {
			var pos = getPos(track, i)
			if (posKind(pos) === 'CH') {
				var ta = getTackingAngle(track, i)
				var sign = posTack(pos) === 'S' ? 1 : -1
				let wind = angle360(segment.initialCourse + sign * ta / 2 + 180)
				addWind(track, { time: track[segment.start].time, wind: wind, reason: 'shift', segment: segment })
				return wind
			}
		}

		if (segment.straight) return null
		if (segment.shift == null) return undefined
		if (!segment.maneuver) return null

		var wind = getCombinedWind(track, i)
		var shift = segment.shift
		var c1 = segment.c1
		var c2 = segment.c2
		// var r1 = segment.r1
		// var r2 = segment.r2
		var a1 = calcShift(wind, c1)
		var a2 = calcShift(wind, c2)
		var aa1 = Math.abs(a1)
		var aa2 = Math.abs(a2)
		var sa1 = signum(a1)
		var sa2 = signum(a2)
		// var sr1 = signum(r1)
		// var sr2 = signum(r2)
		// var sdc = signum(calcShift(c1, c2))
		// var sdr = signum(calcShift(r1, r2))
		var tackingAngle = shift > MIN_TACKING_ANGLE && shift < MAX_TACKING_ANGLE
		var jibingAngle = shift > MIN_JIBING_ANGLE && shift < MAX_JIBING_ANGLE
		var i2 = getWindow(track, segment.end, SMALL_WINDOW)

		// if (a1 && a2 && Math.abs(aa1 - aa2) > 40 ((aa1 < 90 && aa2 > 90) || (aa1 > 90 && aa2 < 90))) {
		//     candidateMark(track, i2, j, aa1.toFixed() + ' -> ' + aa2.toFixed())
		// }
		// if (tackingAngle && r1 && r2 && sr1 != sr2 && sdr == sdc && (!a1 || aa1 + aa2 > 180)) {
		// 	segment.tack = true
		// } else
		if (tackingAngle && a1 && a2 && sa1 !== sa2 && aa1 > 90 && aa2 > 90) {
			segment.tack = true
		} else if (jibingAngle && a1 && a2 && sa1 !== sa2 && aa1 < 90 && aa2 < 90) {
			segment.jibe = true
		}
		// else if (jibingAngle && r1 && r2 && sr1 != sr2 && sdr != sdc) {
		// 	segment.jibe = true
		// }

		if (segment.tack || segment.jibe) {
			let j = getWindow(track, segment.start, TINY_WINDOW)
			var n = track.length - 1
			for (var k = j; k < n; k++) {
				if (Math.abs(calcShift(c1, getCourse(track, k))) > shift * 0.9) {
					break
				}
			}
			segment.time = (track[k].time + track[j].time) / 2
			segment.duration = track[k].time - track[j].time
			var speed = 0.9 * getSpeed(track, i)
			for (var l = k; l < n; l++) {
				var dc = Math.abs(calcShift(c2, getCourse(track, l)))
				if (dc < SMALL_SHIFT && getSpeed(track, l) > speed) {
					break
				}
			}
			segment.recovery = track[l].time - track[k].time
			var maxShift = shift
			for (var m = k; m < i2; m++) {
				maxShift = Math.max(maxShift, Math.abs(calcShift(c1, getCourse(track, m))))
			}
			segment.overturning = Math.abs(maxShift - shift)
		}
		if (segment.tack) {
			let wind = calcBisector(c1 + 180, c2 + 180)
			addWind(track, { time: track[i2].time, wind: wind, reason: 'tack', segment: segment })
			return wind
		}
		return null
	})
}

/**
 * @param {Object} o
 */
function addWind(track, o) {
	console.log('addWind', o)
	o.boat = track.boat && track.boat.name
	o._wind = o.wind
	let { winds, fleet } = track.ctx
	var i = winds.length && pass2(winds, winds.current, o.time)
	winds.splice(i + 1, 0, o)
	var w = winds[i].wind
	var l = winds.length
	for (i++; i < l; i++) {
		let o = winds[i]
		w = angle360(w + calcShift(w, o._wind) * (o.weight || 1 / (fleet.length || 1)))
		o.wind = w
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getSegment(track, i) {
	var l = track.length - 1
	if (i > l) return
	var segment = _getSegment(track, i)
	var n = segment.index + 1
	var segments = track.segments
	for (; ;) {
		if (segments.length > n || segment.end === l) return segment
		_getSegment(track, segment.end + 1)
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
function _getSegment(track, i) {
	return memo(track[i], 'segment', () => {
		var x
		if (i === 0) {
			x = createSegment(track, 0)
		}
		else {
			var segments = track.segments
			for (var k = segments.length ? segments[segments.length - 1].end : 0; k < i; k++) {
				_getSegment(track, k)
			} 
			x = _getSegment(track, i - 1)
			x.end = i

			var c = getCourse(track, i, getWindow(track, i, TINY_WINDOW))

			if (x.index > 0) {
				var straight = x.straight ? x : segments[x.index - 1]
				var shift = calcShift(straight.initialCourse, c)
				var absShift = Math.abs(shift)
				x.maneuver = x.maneuver || absShift > MIN_MANEUVER_SHIFT
			}

			if (x.straight) {
				if (absShift >= LARGE_SHIFT) {
					x = createSegment(track, i)
				}
			} else {
				var i2 = getWindow(track, i, SMALL_WINDOW)
				var c2 = getCourse(track, i, i2)
				if (Math.abs(calcShift(c, c2)) < SMALL_SHIFT
					&& Math.abs(calcShift(c, getCourse(track, i, getWindow(track, i, MEDIUM_WINDOW)))) < SMALL_SHIFT) {
					var curve = x
					x = createSegment(track, i)
					x.straight = true
					x.initialCourse = c
					if (x.index >= 3) {
						var j = getWindow(track, curve.start, TINY_WINDOW)
						var j2 = Math.max(getWindow(track, j, SMALL_WINDOW), getWindow(track, straight.start, TINY_WINDOW))
						if (j2 < j && j < i2 && i2 < i) {
							var c1 = getCourse(track, j, j2)
							curve.shift = Math.abs(calcShift(c1, c2))
							curve.c1 = c1
							curve.c2 = c2
							// curve.r1 = getPitch(track, j, j2) && getRoll(track, j, j2)
							// curve.r2 = getPitch(track, i, i2) && getRoll(track, i, i2)
							getSegmentWind(track, i - 1)
						}
					}
				}
			}
		}
		return x
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 */
export function createSegment(track, i) {
	var segments = track.segments
	var index = segments.length
	var segment = { track: track, index: index, start: i, end: i }
	segments.push(segment)
	return segment
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getPrevSegment(track, i) {
	return track.segments[getSegment(track, i).index - 1]
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getNextSegment(track, i) {
	var segment = track.segments[getSegment(track, i).index + 1]
	return segment && getSegment(track, segment.start)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {object} wp
 * @return {boolean}
 */
export function getCross(track, i, wp, time) {
	if (!i) i = 1
	if (!time) time = 1e99
	return pass2(track, i, time, (point, i) => {
		if (calcCross(track[i - 1], point, wp, wp.gate) && calcCross(wp, wp.gate, track[i - 1], point)) {
			return i
		}
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {boolean}
 */
export function getStarting(track, i) {
	return i <= track.start
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {boolean}
 */
export function getRacing(track, i) {
	if (i == null) i = track.current
	return i >= track.start && i <= (track.finish || 1e9)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getPrevTack(track, i) {
	var segment = getSegment(track, i) // including current
	while (segment) {
		if (segment.tack) return segment
		segment = getPrevSegment(track, segment.start)
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getNextTack(track, i) {
	var segment = getNextSegment(track, i)
	while (segment) {
		if (segment.tack) return segment
		segment = getNextSegment(track, segment.start)
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getPrevJibe(track, i) {
	var segment = getSegment(track, i) // including current
	while (segment) {
		if (segment.jibe) return segment
		segment = getPrevSegment(track, segment.start)
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getRq(track, i, j) {
	var t = track[i].time - track[j].time
	if (t / (i - j) > 3100) return undefined
	var straight = 0
	for (var s = getSegment(track, j); s && s.start < i; s = getNextSegment(track, s.start)) {
		if (s.straight) {
			straight += track[Math.min(i, s.end)].time - track[Math.max(j, s.start)].time
		}
	}
	return round(100 * straight / t)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {function} fn
 * @return {number}
 */
export function getMin(track, i, j, fn) {
	return memo(track[i], 'min_' + j + fn, () => {
		if (j == null) j = i - 1
		var min = Infinity
		for (; i > j; i--) {
			var x = fn(track, i)
			if (x < min) min = x
		}
		return min
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {function} fn
 * @return {number}
 */
export function getMax(track, i, j, fn) {
	return memo(track[i], 'max_' + j + fn, () => {
		if (j == null) j = i - 1
		var max = -Infinity
		for (; i > j; i--) {
			var x = fn(track, i)
			if (x > max) max = x
		}
		return max
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {function} fn
 * @return {number}
 */
export function findMax(track, i, j, fn) {
	return memo(track[i], 'max_i_' + j + fn, () => {
		if (j == null) j = i - 1
		let max = -Infinity
		let ix
		for (; i > j; i--) {
			var x = fn(track, i)
			if (x > max) {
				max = x
				ix = i
			}
		}
		return ix
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {function} fn
 * @return {number}
 */
export function getSum(track, i, j, fn) {
	return memo(track[i], 'sum_' + j + fn, () => {
		if (j == null) j = i - 1
		var sum = 0
		for (; i > j; i--) {
			sum += fn(track, i)
		}
		return sum
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {function} fn
 * @return {number}
 */
export function getAvg(track, i, j, fn, arithmetic) {
	return memo(track[i], 'avg_' + j + fn, () => {
		arithmetic = arithmetic || fn.arithmetic || standardArithmetic
		if (j == null) j = i - 1
		let base = fn(track, i)
		let sum = 0, cnt = 0
		for (i--; i > j; i--) {
			let x = fn(track, i)
			if (isNumber(x)) {
				sum += arithmetic['-'](x, base)
				cnt++
			}
		}
		if (!cnt) return base
		let d = sum / cnt
		return arithmetic['+'](base, d)
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {function} fn
 * @return {number}
 */
export function getSigma2(track, i, j, fn) {
	return memo(track[i], 'sigma2_' + j + fn, () => {
		var m = fn(track, i, j)
		return getAvg(track, i, j, (track, i) => {
			var x = fn(track, i) - m
			return x * x
		})
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} j
 * @param {number} p
 * @param {function} fn
 * @return {number}
 */
export function getPercentile(track, i, j, p, fn) {
	return memo(track[i], 'percentile' + p + '_' + j + fn, () => {
		var xs = []
		for (; i >= j; i--) {
			xs.push(fn(track, i))
		}
		xs.sort((a, b) => b - a)
		return xs[Math.round(xs.length / 100 * p)]
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {number} window
 * @return {number}
 */
export function getWindow(track, i, window) {
	var time = track[i].time - window * 1000;
	for (var x = i; x > 1; x--) {
		if (track[x].time <= time) {
			break
		}
	}
	return x
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getVmg(track, i, j) {
	if (i == null) return track.interpolated.vmg
	if (!getRacing(track, i)) return
	switch (getLegPosSign(track, i)) {
		case 1:
			return -getWindVmg(track, i, j)
		case -1:
			return getWindVmg(track, i, j)
		default:
			return getMarkVmg(track, i, j)
	}
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @param {number} [wind]
 * @return {number}
 */
function getWindVmg(track, i, j, wind) {
	if (wind == null) wind = getCombinedWind(track, i)
	if (wind == null) return
	if (j == null) j = pass(track, i, -2 * TINY_SPAN)
	return calcSpeed(getWindDistance(track, i, j, wind), track[i].time - track[j].time)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @param {number} [wind]
 * @return {number}
 */
function getWindDistance(track, i, j, wind) {
	return memo(track[i], 'windDistance_' + j + '_' + wind, function () {
		if (wind == null) wind = getCombinedWind(track, i)
		return calcProjection(track[j], track[i], wind)
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @param {object} [wp]
 * @return {number}
 */
function getMarkVmg(track, i, j, wp) {
	if (!wp) wp = getWp(track, i)
	if (!wp) return
	if (j == null) j = pass(track, i, -2 * TINY_SPAN)
	return calcSpeed(getDtm(track, j, wp) - getDtm(track, i, wp), track[i].time - track[j].time)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getHeel(track, i, j) {
	if (i == null) return track.interpolated.heel
	let { model, models, initialWind } = track.ctx
	return getRoll(track, i, j)
		|| (model === models.Moth || model === models.PilotGig || initialWind == null
			? null
			: posTack(getPos(track, i)) === 'S' ? -15 : 15
		)
}

/**
 * @param {function} fn
 * @param {number} [window]
 * @return {function}
 */
export function interpolated(fn, window) {
	return (track, time) => {
		var i = pass2(track, track.current, time)
		if (i === track.length - 1) i--
		if (window) var j = getWindow(track, i, window)
		if (j === i) j = null
		return interpolate(
			track[i].time, 
			track[i + 1].time, 
			time, 
			fn(track, i, j), 
			fn(track, i + 1, window ? j + 1 : null),
			fn.arithmetic,
		)
	}
}

export let interLat = interpolated(getLat)
export let interLon = interpolated(getLon)
export let interSpeed = interpolated(getSpeed)
let interSow = interpolated(getSow)
export let interCourse = interpolated(getCourse)
let interPitch = interpolated(getPitch)
export let interHeading = interpolated(getHeading)
export let interHeadingS = interpolated(getHeading, SMALL_WINDOW)
let interDrift = interpolated(getDrift)
let interAwa = interpolated(getAwa)
let interAws = interpolated(getAws)
let interVmg = interpolated(getVmg)
let interHeel = interpolated(getHeel)
let interVmgDirection = interpolated(getVmgDirection)

/**
 * @param {Track} track
 * @param {number} time
 * @return {number}
 */
function interWind(track, time) {
	let { ctx } = track
	let { winds } = ctx
	if (!winds.length) resetCombinedWind(track.ctx)
	var i = pass2(winds, winds.current, time)
	winds.current = i
	var w1 = winds[i]
	var w2 = winds[i + 1]
	if (!w2) return w1.wind
	return interpolate(w1.time, w2.time, time, w1.wind, w2.wind, angularArithmetic)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getCombinedWind(track, i) {
	if (i == null) return track.interpolated.wind
	return interWind(track, track[i].time) || getWind(track, i).twd
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getVmgDirection(track, i) {
	if (!getRacing(track, i)) return
	switch (getLegPosSign(track, i)) {
		case 1:
			return getCombinedWind(track, i) + 180
		case -1:
			return getCombinedWind(track, i)
		default:
			return getRhumbDirection(track, i)
	}
}
getVmgDirection.arithmetic = angularArithmetic

/**
 * @param {Track} track
 * @return {number}
 */
export function getStartTime(track) {
	let { startLine } = track.ctx
	var i = track.start
	if (!i) return
	var p1 = getNose(track, i - 1)
	var p2 = getNose(track, i)
	var d1 = calcDistance(p1, startLine)
	var d2 = calcDistance(p2, startLine)
	var t1 = track[i - 1].time
	var t2 = track[i].time
	return Math.round(t1 + (t2 - t1) * d1 / (d1 + d2))
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {number} [wind]
 * @return {string}
 */
export function getPos(track, i, wind) {
	if (!track[i]) return 'CRS'
	// return memo(track[i], 'pos_' + j, function () {
	if (wind == null) wind = getWind(track, i).twd || getCombinedWind(track, i)
	if (wind != null) {
		return calcPos(wind, getHeading(track, i)/*, time.now - time.start > 0*/)
	} else {
		return getRoll(track, i) > 0 ? 'CRP' : 'CRS'
	}
	// if (j == null || j == i || i == 1 || n == 'CHP' || n == 'CHS') return n

	// var ns = posSpin(n)
	// var nt = posTack(n)
	// var fs = true
	// var ft = true
	// for (var k = 1; k < j; k++) {
	// 	var f = getPos(track, i+k)
	// 	fs = fs && posSpin(f) == ns
	// 	ft = ft && posTack(f) == nt
	// }
	// if (fs && ft) return n

	// var o = getPos(track, i-1, j)
	// var os = posSpin(o) == ns
	// var ot = posTack(o) == nt

	// if (os && ot) return n
	// if (ot && fs) return n
	// if (os && ft) return n

	// if (!fs && !ft) return o
	// if (os || ot) return o

	// if (ft) return o.substr(0, 2) + n[2]
	// if (fs) return n.substr(0, 2) + o[2]

	// return n
	// })
}

/**
 * @param {Index} ix
 * @return {number}
 */
export function getLegWind(ix) {
	let { route, fleet, initialWind } = global
	// return route[ix] && memo(route[ix], 'wind', function () {
		var winds = []
		fleet.forEach(function (track) {
			var leg = getLeg(track, 1)
			while (leg && leg.index < ix) {
				leg = getNextLeg(track, leg.start)
			}
			if (leg /*&& leg.rounding*/) {
				winds.push(getWind(track, leg.end).twd || getCombinedWind(track, leg.end))
			}
		})
		return winds.length ? angle360(avgAngle(winds)) : ix ? getLegWind(ix - 1) : initialWind
	// })
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {string}
 */
export function getLegPos(track, i) {
	if (i == null) i = track.current
	//return memo(track[i], 'legPOS', function() {
	var leg = getLeg(track, i)
	var idx = leg && leg.index
	if (!idx) return ''
	var p1 = track.legs[idx - 1].wp
	var p2 = leg.wp || track[i]
	return calcPos(getLegWind(idx), calcDirection(p1, p2))
	//})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getLegPosSign(track, i) {
	// return signum(90 - Math.abs(getTwa(track, i)))
	let wp = getWp(track, i)
	if (!wp) return
	if (!wp.lat) {
		if (wp.type === 'WM') return 1
		if (wp.type === 'LM') return -1
		if (wp.type === 'Finish Up') return 1
		if (wp.type === 'Finish Down') return -1
		return
}
	return posSign(getLegPos(track, i))
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getNose(track, i) {
	let { model } = track.ctx
	let { length = 10 } = model
	var p = track[i] || track.interpolated
	var h = getHeading(track, i)
	return calcDestination(p, h, length / 2)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getLeg(track, i) {
	var leg = _getLeg(track, i)
	return leg
	// if (BUOYS) return leg
	// if (!leg) return
	// var l = track.length - 1
	// var n = leg.index + 1
	// var legs = track.legs
	// for (;;) {
	// 	if (legs.length > n || leg.end === l || !_getLeg(track, leg.end + 1)) return leg
	// }
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
function _getLeg(track, i) {
	if (i == null) i = track.current
	return track[i] && memo(track[i], 'leg', function () {
		let { start, finishLine, route, model, fleet, onWpChange } = track.ctx
		if (!start) return null
		let { journey } = track.boat || {}
		if (journey && journey.explicitDivision) {
			var divisionId = journey.divisionId
			if (divisionId != null && Number(divisionId) !== Number(start.divisionId)) return null
		}
		if (i > track.finish) return null
		if (i === 0) return createLeg(track, 1)

		let { legs } = track
		var x = legs[legs.length - 1]
		for (var j = x ? x.end : 0; j < i; j++) {
			x = _getLeg(track, j)
			if (!x) return x
		}

		var time = track[i].time
		if (!track.start && time >= start.tillDtm) return null
		x.end = i
		if (!start.fromDtm || time < start.fromDtm - 20e3) return x

		var wps = [x.wp]
		if (start.type === 'variable'
			&& finishLine
			&& x.index
			&& time > time.start + (start.minDuration | 0)
		) {
			wps.push(finishLine)
		}
		var leg = route[x.index]
		if (leg) wps.forEach(function (wp) {
			let rounded
			if (wp.lat) {
				let gate = wp.type !== 'Mark with offset' && wp.gate
				if (!gate) {
					var dtm = getDtm(track, i, wp)
					if (!x.rounding && dtm < 10 * model.length) {
						x.rounding = i
						return false
					} else if (x.rounding && dtm > 10 * model.length) {
						rounded = true
					} else {
						if (!leg.gate) {
							var r1 = route[x.index - 1]
							r1 = r1 && calcDirection(r1.wp, wp)
							var r2 = route[x.index + 1]
							r2 = r2 && calcDirection(r2.wp, wp)
							let d = (r1 && r2 && calcBisector(r1, r2)) || r1 || r2
							leg.gate = { p1: wp, p2: calcDestination(wp, d, 1e4) }
						}
						gate = true
					}
				}

				if (gate) {
					if (!leg.gate) {
						let d = calcDirection(wp, gate)
						leg.gate = {
							p1: calcDestination(wp, d, -3 * model.length),
							p2: calcDestination(gate, d, +3 * model.length),
						}
					}
					gate = leg.gate
					// var p1 = getNose(track, i - 1)
					// var p2 = getNose(track, i)
					let p1 = track[i-1]
					let p2 = track[i]
					rounded = calcCross(p1, p2, gate.p1, gate.p2) && calcCross(gate.p1, gate.p2, p1, p2)
				}
			}
			if (wp.type === 'Start') {
				if (time > start.fromDtm + 30e3) {
					rounded = true
				}
			}
			if (rounded) {
				x.wp = wp
				x.rounding = x.rounding || i - 1
				if (x.index === 0) {
					track.start = i
					fleet.push(track)
					x = createLeg(track, i)
				} else if (wp === finishLine) {
					track.finish = i
					x = null
				} else {
					x = createLeg(track, i)
				}
				return false
			}
			if ((wp.type === 'WM' || wp.type === 'LM') && x.index && track[i].time - track[x.start].time > 3 * MIN) {
				let prevWp = route[x.index - 1].wp
				let step = 10
				let steps = 10
				let approaching = wp => {
					let dists = range(-steps - 1, 0).map(j => calcDistance(track[i + j * step], wp))
					let diffs = zipWith((a, b) => signum(b - a), dists, dists.slice(1))
					return diffs
				}
				let diffs1 = approaching(prevWp)
				let halves1 = splitAt(steps / 2, diffs1)
				if (all(x => x > 0, halves1[0]) && all(x => x < 0, halves1[1])) {
					let m = findMax(track, i, i - steps * step, (track, i) => calcDistance(track[i], prevWp))
					x.rounding = m - step
					wp.lat = getLat(track, m + step, m - step)
					wp.lon = getLon(track, m + step, m - step)
					console.log('WP detected', wp)
					onWpChange(wp)
					x = createLeg(track, i)
				}
				if (all(x => x > 0, diffs1)) {
					let diffs2 = approaching(wp)
					if (all(x => x > 0, diffs2)) {
						delete wp.lat
						delete wp.lon
						console.log('WP missed', wp)
						onWpChange(wp)
					}
				}
			}
		})
		return x
	})
}

/**
 * @param {Track} track
 * @param {Index} i
 */
function createLeg(track, i) {
	let { route } = track.ctx
	var legs = track.legs
	var index = legs.length
	var r = route[index]
	if (!r) return null
	var leg = { index: index, wp: r.wp, start: i, end: i }
	legs.push(leg)
	return leg
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getPrevLeg(track, i) {
	var leg = getLeg(track, i)
	return track.legs[leg ? leg.index - 1 : track.legs.length - 1]
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getNextLeg(track, i) {
	var leg = getLeg(track, i)
	leg = leg && track.legs[leg.index + 1]
	return leg && getLeg(track, leg.start)
}

/**
 * @param {Track} track
 * @return {object[]}
 */
export function getLegs(track) {
	for (var leg = getLeg(track, 1); leg; leg = getNextLeg(track, leg.start)) {
	}
	return track.legs
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getWp(track, i) {
	var leg = getLeg(track, i)
	return leg && leg.wp
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getPrevWp(track, i) {
	var leg = getPrevLeg(track, i)
	return leg && leg.wp
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getNextWp(track, i) {
	var leg = getNextLeg(track, i)
	return leg && leg.wp
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object}
 */
export function getRhumb(track, i) {
	if (i == null) i = track.current
	let wp1 = getPrevWp(track, i)
	let wp2 = getWp(track, i)
	let p1, p2
	if (wp1 && wp2 && wp2.lat) {
		p1 = calcMiddle(wp1)
		p2 = calcMiddle(wp2)
	} else if (!wp1 && !wp2) {
		p1 = track[0]
		p2 = track[i]
	} else {
		return
	}
	return Object.assign({ gate: p2 }, p1)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getRhumbDirection(track, i) {
	var rhumb = getRhumb(track, i)
	if (rhumb) return calcDirection(rhumb)
	let startLineDirection = calcDirection(track.ctx.route[0].wp)
	let wp = getWp(track, i)
	return wp && startLineDirection + (wp.type === 'WM' ? 90 : -90)
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {number}
 */
export function getDtm(track, i, wp) {
	//return memo(track[i], 'dtm', function () {
	if (!wp) wp = getWp(track, i)
	if (!wp) return null
	return calcDistance(track[i], wp)
	//})
}

/**
 * @param {Track} track
 * @param {Index} i
 * @return {object[]}
 */
export function getLegSegments(track, i) {
	let leg = getLeg(track, track.current)
	if (!leg || !leg.index) return []
	getSegment(track, leg.end)
	return (
		track.segments
		.filter(segment => segment.start < leg.end && segment.end > leg.start && segment.initialCourse)
		.map(segment => [track[segment.start], track[segment.end]])
	)
}

/**
 * @param {Track} track
 * @param {number} time
 */
export function passTime(track, time) {
	// if (BUOYS) {
		track.rest = track.rest || []
		let ix = pass2(track, track.current, time)
		if (ix < track.length - 2) {
			resetTrack(track)
			track.rest.splice(0, 0, ...track.splice(ix + 2))
		} else if (ix === track.length - 1 && track.rest.length) {
			let ix = pass2(track.rest, 0, time)
			track.push(...track.rest.splice(0, ix + 1))
		}
		track.current = track.length - 2
	// } else {
	// 	track.current = pass2(track, track.current, time)
	// }
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {[number, number]}
 */
export function getWow(track, i, j) {
	let { twd, tws } = getWind(track, i, j)
	let { speed, direction } = getCurrent(track, i, j)
	return addPolarVectors([tws, twd], [speed, direction + 180])
}

/**
 * @param {Track} track
 * @param {Index} i
 * @param {Index} [j]
 * @return {number}
 */
export function getWowa(track, i, j) {
	let [, d] = getWow(track, i, j)
	let h = getHeading(track, i, j)
	return calcShift(h, d + 180)
}

/**
 * @param {string} s
 * @return {object}
 */
export function parsePolar(s) {
	let a = s.split('\n')
		.filter(r => r)
		.map(r => r.split(';'))
		.map(r => r.map(parseFloat))
		.map(r => r.filter(x => x))
	let l = a[0].length
	return {
		wows: a[0],
		beat: a.slice(2, 2 + l),
		reach: a.slice(2 + l, a.length - l),
		run: a.slice(a.length - l),
	}
}

export function getPolar(track) {
	let { model } = track.ctx
	if (typeof(model.polar) === 'string') {
		model.polar = parsePolar(model.polar)
	}
	return model.polar
}

export function calcTarget(polar, posSign, wows) {
	let a = polar[posSign === 1 ? 'beat' : posSign === -1 ? 'run' : 'reach']
	if (!a) return {}
	let j = polar.wows.findIndex(x => x > wows)
	if (j === 0) return { 
		wowa: a[0][0],
		sow: a[0][1],
		heel: a[0][2],
	}
	if (j === -1) return { 
		wowa: a[a.length - 1][0],
		sow: a[a.length - 1][1],
		heel: a[a.length - 1][2],
	}
	return {
		wowa: interpolate(polar.wows[j - 1], polar.wows[j], wows, a[j - 1][0], a[j][0]),
		sow: interpolate(polar.wows[j - 1], polar.wows[j], wows, a[j - 1][1], a[j][1]),
		heel: interpolate(polar.wows[j - 1], polar.wows[j], wows, a[j - 1][2], a[j][2]),
	}
}

export function getTarget(track, i, j) {
	return memo(track[i], 'target', () => {
		let polar = getPolar(track)
		// let s = getLegPosSign(track, i)
		let s = signum(90 - Math.abs(getTwa(track, i)))
		let [wows] = getWow(track, i, j)
		let target = calcTarget(polar, s, wows)
		if (posTack(getPos(track, i)) === 'S') {
			target.heel = -target.heel
		} else {
			target.wowa = -target.wowa
		}
		return target
	})
}

export function getTargetSow(track, i, j) {
	return getTarget(track, i, j).sow
}

export function getTargetWowa(track, i, j) {
	return getTarget(track, i, j).wowa
}

// export function getTargetAwa(track, i, j) {
// 	let { tws } = getWind(track, i, j)
// 	let { twa, sow } = getTarget(track, i, j)
// 	let sog = sow // Math.max(sow, getSpeed(track, i, j)) // TODO: account current
// 	let x = Math.cos(toRadians(twa)) * tws + sog
// 	let y = Math.sin(toRadians(twa)) * tws
// 	let awa = toDegrees(Math.atan2(y, x))
// 	return awa
// }

export function getBearing(track, i, j, wp) {
	if (j == null) {
		if (!wp) wp = getWp(track, i)
		if (!wp) return
		return calcDirection(track[i], wp)
	}
	var leg = getLeg(track, i)
	j = Math.max(j, leg && leg.start)
	return angle360(getHeading(track, i, j) + getAzimuth(track, i, j))
}

export function getAzimuth(track, i, j) {
	if (j) {
		var leg = getLeg(track, i)
		j = Math.max(j, leg && leg.start)
	}
	return getAvg(track, i, j, function (track, i) {
		return calcShift(getHeading(track, i), getBearing(track, i))
	})
}

export function getTurn(track, i, j) {
	let wp = getWp(track, i)
	if (!wp || !wp.lat) return
	let d = calcDirection(track[i], wp)
	let cog = getCourse(track, i, j)
	return calcShift(cog, d)
}

export function getStartVector(track, i) {
	let wow = getWow(track, i)
	let polar = getPolar(track)
	let target = calcTarget(polar, 1, wow[0])
	let cow = wow[1] + 180 - target.wowa
	let { speed: cs, direction: cd } = getCurrent(track, i)
	let x = addPolarVectors([target.sow, cow], [cs, cd])
	return x[0] && x[1] ? x : []
}

/*
function getAtll(track, i, tack) {
	var h = getLlHeading(track, i, tack)
	var w = getCombinedWind(track, i)
	var b = getBearing(track, i)
	return calcShift(b + 180, h) * signum(calcShift(w, h))
}

function getUpwind(track, i) {
	var lls = getLl(track, i, 'S')
	if (!lls) return
	var llp = getLl(track, i, 'P')
	var wp = getWp(track, i)
	var wind = getCombinedWind(track, i)
	var w = calcDestination(wp, wind + 180, 1e3)
	var p = track[i]
	return calcCross(p, w, lls, lls.gate) && calcCross(p, w, llp, llp.gate)
}
*/

// /**
//  * @param {Track} track
//  * @param {Index} [i]
// / * @return {object}
//  */
// function kalmanHeading(track, i) {
// 	return memo(track[i], 'kalHeading', () => {
// 		let { kalmanFilter } = track
// 		if (!kalmanFilter) {
// 			kalmanFilter = new KalmanFilter()
// 			track.kalmanFilter = kalmanFilter
// 		}
// 		if (!i) return null
// 		let previousCorrected = kalmanHeading(track, i - 1)
// 		let observation = getHeading(track, i, i - 1)
// 		return kalmanFilter.filter({ previousCorrected, observation })
// 	})
// }

// /**
//  * @param {Track} track
//  * @param {Index} [i]
// / * @return {number}
//  */
// export function kalHeading(track, i) {
// 	return kalmanHeading(track, i).mean
// }
