import Header from '../Header'
import { pass } from '../tools'
import { SIGNALK_HOST } from '../settings'
import { round } from '../tools'
import CssBaseline from '@material-ui/core/CssBaseline'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
// import FormLabel from '@material-ui/core/FormLabel'
// import FormControl from '@material-ui/core/FormControl'
// import FormGroup from '@material-ui/core/FormGroup'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		// padding: theme.spacing(2),
	},
}))

export default function Status({ sensors = {}, upload = {}, track }) {
	let { geolocation, signalK, orientation } = sensors
	let classes = useStyles()
	let i = track.current
	let p = track[i] || {}
	let j = i && pass(track, i, -60e3)
	let rate = i && round((i - j) / (track[i].time - track[j].time) * 60e3)
	let href = `http://${SIGNALK_HOST}`
	return (
		<>
			<Header>Status</Header>
			<Container component="main">
				<CssBaseline />
				<Grid container>
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell><Typography variant="h5">RQPod</Typography></TableCell>
										<TableCell><a href={href}>{href}</a></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>Connected?</TableCell>
										<TableCell>{String(signalK.status.connected)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Time</TableCell>
										<TableCell>{signalK.data.time && new Date(signalK.data.time).toISOString()}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Lat</TableCell>
										<TableCell>{round(signalK.data.lat, 6)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Lon</TableCell>
										<TableCell>{round(signalK.data.lon, 6)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Heading</TableCell>
										<TableCell>{round(signalK.data.heading)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>SoW</TableCell>
										<TableCell>{round(signalK.data.sow, 1)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>AWA</TableCell>
										<TableCell>{round(signalK.data.awa)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>AWS</TableCell>
										<TableCell>{round(signalK.data.aws, 1)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Depth</TableCell>
										<TableCell>{round(signalK.data.depth, 1)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Temperature</TableCell>
										<TableCell>{round(signalK.data.temperature)}</TableCell>
									</TableRow>
									{signalK.data.time &&
										<>
											<TableRow>
												<TableCell>Count</TableCell>
												<TableCell>{track.length}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Per minute</TableCell>
												<TableCell>{rate || 0}</TableCell>
											</TableRow>
										</>
									}
									{signalK.status.error &&
										<TableRow>
											<TableCell>Error</TableCell>
											<TableCell>{signalK.status.error}</TableCell>
										</TableRow>
									}
								</TableBody>
								<TableHead>
									<TableRow>
										<TableCell><Typography variant="h5">Geolocation</Typography></TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>Time</TableCell>
										<TableCell>{geolocation.data.time && new Date(geolocation.data.time).toISOString()}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Lat</TableCell>
										<TableCell>{round(geolocation.data.lat, 6)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Lon</TableCell>
										<TableCell>{round(geolocation.data.lon, 6)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Accuracy</TableCell>
										<TableCell>{round(geolocation.data.accuracy) || 0}</TableCell>
									</TableRow>
									{!signalK.data.time &&
										<>
											<TableRow>
												<TableCell>Count</TableCell>
												<TableCell>{track.length}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Per minute</TableCell>
												<TableCell>{rate || 0}</TableCell>
											</TableRow>
										</>
									}
									{geolocation.status.error &&
										<TableRow>
											<TableCell>Error</TableCell>
											<TableCell>{geolocation.status.error.message || geolocation.status.error.toString()}</TableCell>
										</TableRow>
									}	
								</TableBody>
								<TableHead>
									<TableRow>
										<TableCell><Typography variant="h5">Orientation</Typography></TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>Alpha</TableCell>
										<TableCell>{round(orientation.data.alpha) || 0}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Beta</TableCell>
										<TableCell>{round(orientation.data.beta) || 0}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Gamma</TableCell>
										<TableCell>{round(orientation.data.gamma) || 0}</TableCell>
									</TableRow>
									{/*
									<TableRow>
										<TableCell>Heading</TableCell>
										<TableCell>{round(p.heading) || 0}</TableCell>
									</TableRow>
									*/}
									<TableRow>
										<TableCell>Roll</TableCell>
										<TableCell>{round(p.roll) || 0}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Pitch</TableCell>
										<TableCell>{round(p.pitch) || 0}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Count</TableCell>
										<TableCell>{orientation.status.count}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell>Per minute</TableCell>
										<TableCell>{round(orientation.status.rate) || 0}</TableCell>
									</TableRow>
								</TableBody>
								<TableHead>
									<TableRow>
										<TableCell><Typography variant="h5">Upload</Typography></TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>Uploaded</TableCell>
										<TableCell>{upload.uploaded} / {track.length}</TableCell>
									</TableRow>
									{upload.error &&
										<TableRow>
											<TableCell>Error</TableCell>
											<TableCell>{upload.error}</TableCell>
										</TableRow>
									}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
					{/*
					<Grid item xs={12}>
						<Paper>
							<Table size="small">
								<TableBody>
									{signalK.log.map(({ time, event, info }, ix) => (
										<TableRow key={ix}>
											<TableCell>{time}</TableCell>
											<TableCell>{event}</TableCell>
											<TableCell>{info}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
					</Grid>
					*/}
				</Grid>
			</Container>
		</>
	)
}