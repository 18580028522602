import { signum } from '../tools'
import { getSow, getWowa, getTargetSow, getTargetWowa, getTwa } from "../algorithm"
import View from './Efficiency'

export default function Efficiency(ctx) {
	let { track, i, theme } = ctx
	// let posSign = getLegPosSign(track, i)
	let posSign = signum(90 - Math.abs(getTwa(track, i)))
	let targetSow = getTargetSow(track, i)
	let targetWowa = getTargetWowa(track, i)
	let sow = getSow(track, i)
	let wowa = getWowa(track, i)
	let history = track.length < 22 ? [] : track.slice(-10).map((_, j) => {
		let k = i - 2 * j - i % 2
		return {
			key: k,
			sow: getSow(track, k),
			wowa: getWowa(track, k),
		}
	})

	return View({ posSign, targetSow, targetWowa, sow, wowa, history, theme })
}