import { Link } from "react-router-dom"
// import { useSwipeable } from 'react-swipeable'
import { useStore } from '../store'
import Menu from '../Menu'
import Start from '../Start'
import Navigation from '../Navigation'
import Helming from '../Helming'
import Elements from '../Elements'
import Efficiency from '../Efficiency'
import IconButton from '@material-ui/core/IconButton'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import Alert from '@material-ui/lab/Alert'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import Button from '@material-ui/core/Button'

export default function Boards(ctx) {
	let { timeToStart, track, replay, theme, sensors } = ctx
	let [screen, setScreen] = useStore('screen', 0)
	let navigating = timeToStart < -10e3 && track.start
	let screens = [
		navigating ? Navigation : Start,
		...(sensors.signalK.status.connected ? [Elements, Efficiency] : []),
		...(sensors.orientation.count > 1000 ? [Helming] : [])
	]

	while (screen < 0) screen += screens.length
	while (screen >= screens.length) screen -= screens.length
	let Screen = screens[screen]

	// let handlers = useSwipeable({
	// 	delta: 100,
	// 	trackMouse: true,
	// 	onSwipedLeft: () => isMounted() && setScreen(screen + 1),
	// 	onSwipedRight: () => isMounted() && setScreen(screen - 1),
	// })

	let ready = replay || (track.length > 3 /*&& Object.keys(sk.status).every(k => sk.status[k])*/)
	let Ready = (
		<>
			<Screen {...ctx} />
			<IconButton onClick={() => setScreen(screen + 1)} style={{ color: theme.primary, position: 'absolute', top: '50%', right: 0, zIndex: 1e3, marginTop: -27 }} >
				<ArrowForwardIosIcon style={{ fontSize: "3rem" }} />
			</IconButton>
		</>
	)
	let Waiting = (
		<div style={{ background: theme.background, height: '100%', padding: 10 }}>
			<Alert severity="info"
				icon={<HourglassEmptyIcon />}
				action={
					<Button component={Link} to="/status" color="inherit" size="small">
						STATUS
					</Button>
				}
			>
				Waiting for sensors
			</Alert>
		</div>
	)

	return (
		<div style={{ height: "100%", overflow: "hidden" }} /*{...handlers}*/>
			<Menu {...ctx} />
			{ready ? Ready : Waiting}
		</div>
	)
}