import Client from '@signalk/client'
import set from 'set-value'
import { useState } from 'react'
import { SIGNALK_HOST } from './settings'
import { toDegrees, ms2kn, angle360 } from './location'
import { calcMagneticDeclination } from './geomagnetism'
import { useEffectOnce } from 'react-use'

const config = {
	hostname: SIGNALK_HOST,
	useTLS: false,
	port: 80, // 3443,
	useAuthentication: false,
	username: 'raceQs',
	password: 'Express27',
	autoConnect: true,
	reconnect: true,
	notifications: false,
	subscriptions: [
		{
			context: 'self',
			subscribe: [
				{ path: 'navigation.*' },
				{ path: 'environment.*' },
			],
		},
	],
}

const initialStatus = {
	connected: false,
}

let current = {}

export function useSignalK() {
	let [data, setData] = useState({})
	let [status, setStatus] = useState(initialStatus)
	let [log, setLog] = useState([])

	let onEvent = event => (info, ...rest) => {
		console.log('SK.' + event, info, ...rest)
		info = typeof (info) === 'object' && event !== 'error' ? JSON.stringify(info) : String(info)
		switch (event) {
			case 'connect':
				setStatus(status => ({ ...status, connected: true }))
				break
			case 'disconnect':
				setStatus(initialStatus)
				current = {}
				break
			// case 'error':
			// 	setStatus(status => ({ ...status, error: info }))
			// 	break
			default:
		}
		setLog(log => [...log.slice(-1000), { time: new Date().toISOString(), event, info }])
	}

	let onDelta = delta => {
		delta.updates.forEach(u => {
			u.values.forEach(v => {
				if (v.path === 'navigation.position' && (u.source.talker[0] !== 'G' || u.source.sentence !== 'RMC')) return
				set(current, v.path, v.value)
				let { navigation = {}, environment = {} } = current
				// let { attitude = {} } = navigation
				let { wind = {}, depth = {}, water = {} } = environment
				if (v.path === 'navigation.position') {
					let time = new Date(u.timestamp).valueOf()
					if (u.$source.startsWith('multiplexedFromFile')) time = new Date().valueOf()
					// console.log('SK', time, u, v)
					let p = {
						time,
						lat: navigation.position.latitude,
						lon: navigation.position.longitude,
					}
					// if (navigation.racing) console.log('SK navigation.racing', navigation.racing)
					setData({
						...p,
						accuracy: undefined,
						heading: angle360(toDegrees(navigation.headingMagnetic) + calcMagneticDeclination(p)),
						// roll: toDegrees(attitude.roll),
						// pitch: toDegrees(attitude.pitch),
						// yaw: toDegrees(attitude.yaw),
						sow: ms2kn(navigation.speedThroughWater),
						awa: toDegrees(wind.angleApparent),
						aws: ms2kn(wind.speedApparent),
						depth: depth.belowKeel,
						temperature: water.temperature - 273.15
					})
					setStatus(status => status.error ? { ...status, error: null } : status)
				}
			})
		})
	}

	useEffectOnce(() => {
		let client = new Client(config)
		client.on('connect', onEvent('connect'))
		client.on('delta', onDelta)
		client.on('disconnect', onEvent('disconnect'))
		client.on('connectionInfo', onEvent('connectionInfo'))
		// client.on('self', onEvent('self'))
		client.on('hitMaxRetries', onEvent('hitMaxRetries'))
		// client.on('backOffBeforeReconnect', onEvent('backOffBeforeReconnect'))
		// client.on('message', onEvent('message'))
		client.on('notification', onEvent('notification'))
		client.on('authenticated', onEvent('authenticated'))
		client.on('error', onEvent('error'))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// return client.API().then(api => global.skApi = api)
	return { data, status, log }
}
