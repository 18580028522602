function Boat({ length = 60 }) {
    const boatBase = length/5

    return (
        <g>
            <path id="boatBase" d={`
                M ${-boatBase/2} ${length/2}
                h ${boatBase} 
                C ${boatBase*1.5} 0 ${boatBase/1.3} ${-length/3.5} 0 ${-length/2}
                C ${-boatBase/1.3} ${-length/3.5} ${-boatBase*1.5} 0 ${-boatBase/2} ${length/2} 
                h ${boatBase}             
            `}/>
        </g>
    )
}

export default Boat 
