// import { lowPass } from './location'
import { useState } from 'react'
import { useSignalK } from './useSignalK'
import useGeolocation from './useGeolocation'
import { useInterval } from 'react-use'
import { angle180 } from './location'
import gps from './test/gps.json'

let orientation = {
	data: {},
	status: {
		start: new Date().valueOf(),
		count: 0,
	},
}
window.addEventListener("deviceorientationabsolute", o => {
	let { data, status } = orientation
	// console.log('Orientation', o)
	let now = new Date().valueOf()
	status.count++
	status.rate = status.count / (now - status.start) * 60e3
	// orientation.alpha = lowPass(0.5, orientation.alpha, o.alpha)
	// orientation.beta  = lowPass(0.5, orientation.beta,  o.beta)
	// orientation.gamma = lowPass(0.5, orientation.gamma, o.gamma)
	data.alpha = o.alpha
	data.beta  = o.beta
	data.gamma = o.gamma
}, true)

function normalizeOrientation({ beta, gamma }) {
	if (!beta && !gamma) return {}
	let back = Math.abs(beta) > 90
	let left = gamma > 0
	if (back) left = !left
	let roll, pitch
	if (back && left) {
		roll = 180 + beta
		pitch = -gamma - 90
	} else if (back && !left) {
		roll = 180 - beta
		pitch = gamma - 90
	} else if (!back && left) {
		roll = -beta
		pitch = 90 - gamma
	} else {
		roll = beta
		pitch = 90 + gamma
	}
	roll = angle180(roll)
	pitch = angle180(pitch)
	return { roll, pitch }
}

export default function useSensors(simulate) {
	let geolocation = useGeolocation()
	let signalK = useSignalK()
	let [sim, setSim] = useState({})
	if (process.env.NODE_ENV === 'development') {
		if (simulate) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useInterval(() => {
				gps.i = gps.i || 0
				let g = gps[gps.i]
				delete g.heading
				sim.roll = (sim.roll || 22) + Math.random() * 2 - 1
				sim.pitch = (sim.pitch || 0) + Math.random() * 0.2 - 0.1
				// console.log('Sensors.interval', gps.i, g)
				setSim({ ...sim, ...g })
				gps.i++
			}, 1e3)
		}
	}
	let o = normalizeOrientation(orientation.data)
	return {
		data: {
			...(signalK.status.connected ? signalK.data : geolocation.data),
			// ...sim,
			...o,
		},
		geolocation,
		signalK,
		orientation: {
			...orientation,
			data: {
				...orientation.data,
				...o,
			},
		},
	}
}
