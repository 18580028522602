import { FONT_SIZE, DEVICE_HEIGHT, DEVICE_WIDTH, LINE_WIDTH } from '../settings'
import Boat from '../ViewComponents/Boat'
import WindArrow from "../ViewComponents/WindArrow"
import CurrentArrow from "../ViewComponents/CurrentArrow"
import { Display, NumberDisplays } from '../ViewComponents/NumberDisplays'

export default function Elements({ cog, tws, awa, twa, heading, currentSpeed, currentDirection, theme }) {
    let boatLenScaled = DEVICE_HEIGHT/6
    let currentAngle = currentDirection && (currentDirection - (heading || 0))
    
    return (
        <svg 
			viewBox={`${-DEVICE_WIDTH/2} 0 ${DEVICE_WIDTH} ${DEVICE_HEIGHT}`}  
			style={{ 
                backgroundColor: theme.background, 
                height: "100%", 
                width: "100%", 
                color: theme.primary, 
                fontSize: FONT_SIZE
            }} 
			stroke={theme.primary} fill={theme.primary} strokeWidth={LINE_WIDTH}
		>             
            <line x1="0" y1={DEVICE_HEIGHT/2} x2="0" y2="0" strokeDasharray="4 6"/>

            {cog && <g transform={`rotate(${cog-heading} 0 ${DEVICE_HEIGHT/2})`} >
                <line x1="0" y1={DEVICE_HEIGHT/2} x2="0" y2="0"/>
            </g>}

            <g transform={`translate(0, ${DEVICE_HEIGHT/2})`} stroke={theme.background}>
                <Boat length={boatLenScaled}/>                
            </g> 

            {currentAngle && <g transform={`rotate(${currentAngle} 0 ${DEVICE_HEIGHT/2})`} >
                <g transform={`translate(0 ${DEVICE_HEIGHT/2+boatLenScaled/1.5})`}>
                    <CurrentArrow fill={theme.secondary}/>
                </g>
            </g>}  

            {twa && <g transform={`rotate(${twa} 0 ${DEVICE_HEIGHT/2})`} >
                <g transform={`translate(0 ${DEVICE_HEIGHT/2-boatLenScaled/1.5}) `}>
                    <WindArrow/>
                </g>
            </g>} 

            <NumberDisplays height={DEVICE_HEIGHT} width={DEVICE_WIDTH} theme={theme}>
				{isFinite(awa) && <Display value={Math.abs(awa).toFixed()} text={"AWA \u00B0"} textPlace="bottom" place="left" />}
                {isFinite(twa) && <Display value={Math.abs(twa).toFixed()} text={"TWA \u00B0"} textPlace="bottom"/>}
                {isFinite(currentSpeed) && <Display value={currentSpeed.toFixed(1)} text="Current Speed kn" place="left"/>}
                {isFinite(tws) && <Display value={tws.toFixed(tws < 10 ? 1 : 0)} text="TWS kn"/>}
            </NumberDisplays>
        </svg>              
    )           
}