import { useHistory } from "react-router"
import Typography from '@material-ui/core/Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export default function Header({ children }) {
	const history = useHistory()
	return (
		<>
			<AppBar position="sticky">
				<Toolbar>
					<IconButton onClick={history.goBack} edge="start" color="inherit">
						<ArrowBackIcon />
					</IconButton>
					<Typography variant="h6">
						{children}
					</Typography>
				</Toolbar>
			</AppBar>
			{/*<Toolbar />*/}
		</>
	)
}
