import WindArrow from "../ViewComponents/WindArrow"
import CurrentArrow from "../ViewComponents/CurrentArrow"
import BigTriangle from "../ViewComponents/BigTriangle"

export default function NonRacing({ cog, twd, currentDirection, theme, height }) {
    
    let boatLenScaled = height/4
    let currentAngle = (currentDirection - cog) || 0
    let windAngle = (180 - (cog - twd)) || 0    
    
    return (
        <g>
            <g transform={`translate(0, ${height/2-boatLenScaled/2}) `}>
                <BigTriangle height={boatLenScaled} stroke={theme.background}/>                
            </g> 

            <g transform={`rotate(${currentAngle} 0 ${height/2})`} >
                <g transform={`translate(0 ${height/2+boatLenScaled})`}>
                    <CurrentArrow fill={theme.secondary}/>
                </g>
            </g>                     
            <g transform={`rotate(${windAngle} 0 ${height/2})`} >
                <g transform={`translate(0 ${height/2-boatLenScaled}) `}>
                    <WindArrow/>
                </g>
            </g> 
        </g>        
    )           
}