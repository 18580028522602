import ThickArrow from "../ViewComponents/ThickArrow"
import { SvgButton } from "../ViewComponents/Buttons"
import { DEVICE_HEIGHT, DEVICE_WIDTH } from '../settings'
import useBlink from '../useBlink'

export default function Setup({ position, rcb, pin, windAngle, onRcb, onPin }) {
    const baseline = DEVICE_HEIGHT / 2
    const from = -DEVICE_WIDTH / 6
    const till = DEVICE_WIDTH / 6
    const btnSize = { height: 100, width: 200 }

    let [pinVisible, setPinBlink] = useBlink(6, 500)
    let [rcbVisible, setRcbBlink] = useBlink(6, 500)

	const btnPosY = baseline - btnSize.height / 2 - 6

    const btnPosX = {
		left: from - btnSize.width,
        right: till,
    }   
        
    return (        
        <g>  
			<SvgButton x={btnPosX.left} y={btnPosY} text="PIN" size={btnSize} onClick={() => { setPinBlink(); onPin(position) }} />
			<SvgButton x={btnPosX.right} y={btnPosY} text="RCB" size={btnSize} onClick={() => { setRcbBlink(); onRcb(position) }} />

			{pin && pinVisible && <line x1={from} y1={baseline} x2={0} y2={baseline}/>}
            {rcb && rcbVisible && <line x1={0} y1={baseline} x2={till} y2={baseline}/>}
			
			{rcb && pin && windAngle && <g transform={`rotate(${windAngle || 0}, 0, ${baseline})`}>
				<g transform={`translate(0, ${baseline - 5})`} >
					<ThickArrow fill="#4365B5" />
				</g>
				<line x1={from} y1={baseline} x2={till} y2={baseline} strokeDasharray="10" />
			</g>}
        </g>
    )
}
