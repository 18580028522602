// @ts-check
import View from './Start'
import { getWind, getHeading, getSow, getStartVector } from '../algorithm'
import { pick } from 'rambda'

export default function Start(ctx) {
	let { track, i } = ctx
	let position = track[i]
	if (!i || !position) return null
	position = pick('lat,lon', position)
	let { twd } = getWind(track, i)
	let heading = getHeading(track, i, i - 1)
	let sow = getSow(track, i)
	let startVector = getStartVector(track, i)
	let props = { position, twd, heading, sow, startVector, ...ctx }
	return <View {...props} />
}
