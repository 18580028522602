import TextField from '@material-ui/core/TextField';

function Input({label, value, onChange, type="text"}) {
    return (
        <TextField 
            fullWidth
            label={label}
            value={value}
            onChange={onChange}
            type={type}
            style={{ marginBottom: 40 }}
            inputProps={{style: {fontSize: 30, marginTop: 16}}} 
            InputLabelProps={{style: {fontSize: 30, paddingBottom: 5}}}
        /> 
    )
}

export default Input
