import { cloneElement } from 'react'

export function NumberDisplays({ height, width, theme, children }) {
    let rowStyle={
        display: "flex", 
        justifyContent: "space-between", 
        // padding: 10,
    }
    if (!Array.isArray(children)) children = [children]
    children = children
        .map((child, i) => {
            if (child === null || child === undefined || child === false || Number.isNaN(child)) {
                return <label key={i}/>
            } else {
                return cloneElement(child, {theme, key:i})
            } 
        })
        .concat(Array(4-children.length).map((_, i) => <label key={`dummy${i}`}/>))
    
    return (
        <foreignObject x={-width/2} y="0" width={width} height={height} style={{pointerEvents: "none"}}>
            <div style={{display:"flex", flexDirection:"column", justifyContent: "space-between", height: "100%",  }}>            
                <div style={{...rowStyle, alignItems: "flex-start"}}>
                    {children.slice(0, 2)}
                </div>
                <div style={{...rowStyle, alignItems: "flex-end"}}>
                    {children.slice(2)}
                </div>              
            </div>                      
        </foreignObject>
    )
}

export function Display({ value, text, place="right", textPlace="top", unit, theme, border }) {
    let labelStyle = {
		fontFamily: '"Arial Narrow", sans-serif-condensed, Helvetica, Arial, sans-serif',
		fontStretch: 'condensed',
        fontWeight: 'bold',
        marginBottom: 0,
        backgroundColor: theme.background,
        display: 'inline-block',
    }

    let number = [
        <div key="container">
            <div style={{fontSize: "4em", lineHeight: "85%", border: border + "px solid " + theme.primary, ...labelStyle}}>{value}</div>
            {unit && <label style={labelStyle}> {unit}</label>}
        </div> 
    ]
    let label = text ? <label style={labelStyle} key="text">{text}</label> : ''

    let rows = textPlace === "top" ? [label, number] : [number, label]

    return (
        <div style={{textAlign: place, fontSize: "0.3em", lineHeight: "initial"}} >
            {rows}            
        </div>
    )
}
