function CurrentArrow({ width=150, fill="grey" }) {
    
    const length = width/2.5
    const pad = length/4

    const wave = `
        M ${-width/2} ${length}
        q ${width/10} ${-pad/2} ${width/5} 0
        t ${width/5} 0, ${width/5} 0, ${width/5} 0, ${width/5} 0
    `

    return (
        <g strokeWidth="4" fill={fill}>
            <clipPath id="arrow">
                <polygon points={`${width/2} ${length} 0 0 ${-width/2} ${length}`} />
            </clipPath>
            <path d={`
                M ${width/2} ${length}
                L 0 0 
                L ${-width/2} ${length}
                ${wave}
            `}/>
            <g clipPath="url(#arrow)" >
                <g transform={`translate(0 ${-pad})`} fill="transparent"> 
                    <path d={wave} id="wave2"/>
                </g>
                <g transform={`translate(0 ${-pad*2})`} fill="transparent"> 
                    <path d={wave} id="wave2"/>
                </g>
            </g>
            
        </g>
    )
}

export default CurrentArrow