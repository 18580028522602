function BigTriangle({ height=38 }) {
    const width = height/2
    return (
        <polygon 
            points={`0,0 ${-width/2},${height} ${width/2},${height}`} 
            strokeWidth="8"
            fill="none"
            // fillOpacity="0.8"
        /> 
    )
}

export default BigTriangle