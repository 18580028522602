import { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom"
import { useStore } from './store'
import { findById } from './tools'
import { MIN, HOUR, SIM } from './settings'
import { pick } from 'rambda'
import themes from './themes'
import { models } from './models'
import { resetTrack, resetRoute, resetCombinedWind, calc } from './algorithm'
import { calcDistance } from './location'
import useUserId from './useUserId'
import useWaypoints from './useWaypoints'
import useUpload from './useUpload'
import useSensors from './useSensors'

let winds = []

function createTrack() {
	let track = []
	track.legs = []
	track.segments = []
	track.current = 0
	return track
}

export function useSetup(replay) {
	let [settings, setSettings] = useStore('settings', { theme: 'dark' })
	let userId = useUserId(settings.email)
	let [track, setTrack] = useState(createTrack)
	let [lastGps, setLastGps] = useStore('lastGps')
	let [rcb, setRcb] = useStore('rcb')
	let [pin, setPin] = useStore('pin')
	let [waypoints, setWaypoints] = useWaypoints(userId)
	let [route, setRoute] = useStore('route', [{ waypointId: 'Start' }])
	let [{ startTime, timeToStart }, setTime] = useState({ timeToStart: 5 * 60e3 })
	if (startTime) {
		let now = new Date().valueOf()
		timeToStart = startTime - now
	}
	let history = useHistory()
	let upload = useUpload(!replay && { track, settings, userId})

	let forgetMarks = () => {
		console.trace('forgetMarks')
		waypoints.filter(wp => !wp.group).forEach(wp => {
			delete wp.lat
			delete wp.lon
			delete wp.gate
		})
		setWaypoints([...waypoints])
	}
	let forgetStartLine = () => {
		resetCombinedWind(ctx)
		setRcb(undefined)
		setPin(undefined)
	}

	let sensors = useSensors(!replay && SIM)
	let { data } = sensors
	useEffect(() => {
		if (replay) return
		if (!data.time) return
		if (data.accuracy > 10) return
		// console.log('onData', track.length, p, lastGps)
		if (lastGps) {
			if (data.time - lastGps.time > 6 * HOUR) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				track = createTrack()
				resetCombinedWind(ctx)
				forgetMarks()
				if (calcDistance(data, lastGps) > 1e3) {
					forgetStartLine()
				}
			}
			if (data.time - lastGps.time > 500) {
				track.push(data)
			}
			setTrack(track)
		}
		setLastGps(data)
		if (track.length > 1) {
			calc(track.ctx, data.time)
		}
	}, [data && data.time, replay])

	let time = { start: startTime, now: (track[track.current] || {}).time }
	let startLine = rcb && pin && { ...rcb, gate: pin }
	let start = { fromDtm: startTime, tillDtm: startTime + 5 * MIN }

	if (replay) {
		if (!replay.track) return null
		track = replay.track
		time = replay.time
		startLine = replay.startLine
		rcb = startLine ? pick('lat,lon', startLine) : rcb
		pin = startLine ? startLine.gate : pin
		start = replay.start
		timeToStart = start ? start.fromDtm - time.now : timeToStart
		startTime = (start && start.fromDtm) || startTime
	}

	route = route.map(({ waypointId }) => ({
		waypointId, wp: findById(waypoints, waypointId)
	})).filter(x => x.wp)

	if (rcb && pin) {
		Object.assign(findById(waypoints, 'Start'), rcb, { gate: pin })
		Object.assign(findById(waypoints, 'Finish Down'), rcb)
	}

	let onWpChange = wp => setTimeout(() => {
		console.log('onWpChange', wp)
		let wm = findById(waypoints, 'WM')
		let finishUp = findById(waypoints, 'Finish Up')
		Object.assign(finishUp, pick('lat,lon', wm))
		setWaypoints([...waypoints])
		global.redraw && global.redraw()
	}, 1)
	let onRoute = xs => {
		resetTrack(track)
		resetRoute(xs)
		resetCombinedWind(ctx)
		setRoute([...xs])
	}
	let onReset = () => {
		resetTrack(track)
		setTime({ timeToStart: 5 * MIN })
	}
	let onRcb = rcb => {
		resetCombinedWind(ctx)
		setRcb(rcb)
	}
	let onPin = pin => {
		resetCombinedWind(ctx)
		setPin(pin)
	}
	let gun = () => {
		resetTrack(track)
		let now = new Date().valueOf()
		if (!startTime) { // start
			setTimeout(() => history.push('/route'), 3e3)
			startTime = now + timeToStart
		} else if (startTime > now) { // sync
			timeToStart = startTime - now
			timeToStart = Math.round(timeToStart / 60e3) * 60e3
			startTime = now + timeToStart
		} else { // reset
			timeToStart = 5 * MIN
			startTime = undefined
		}
		setTime({ startTime, timeToStart })
	}
	let setTimeToStart = fn => setTime(({ startTime, timeToStart }) => {
		if (startTime) {
			let now = new Date().valueOf()
			timeToStart = startTime - now
			timeToStart = fn(timeToStart)
			startTime = now + timeToStart
		} else {
			timeToStart = fn(timeToStart)
		}
		return { startTime, timeToStart }
	})

	let ctx = {
		replay,
		settings,
		track,
		i: track.current,
		fleet: [track],
		rcb,
		pin,
		startLine,
		startTime,
		timeToStart,
		start,
		time,
		waypoints,
		route,
		winds,
		model: models[settings.model] || models.J105,
		models,
		theme: themes[settings.theme || 'dark'],
		userId,
		sensors,
		upload,
		onSettings: xs => setSettings({ ...settings, ...xs }),
		onReset,
		onRcb,
		onPin,
		gun,
		setTimeToStart,
		onWpChange,
		onRoute,
	}
	track.ctx = ctx
	Object.assign(global, ctx)
	return ctx
}
