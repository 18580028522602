import { useStore } from '../store'
import { Link } from "react-router-dom"
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
// import Divider from '@material-ui/core/Divider'
import MenuIcon from '@material-ui/icons/Menu'
import EditLocationIcon from '@material-ui/icons/EditLocation'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import SettingsIcon from '@material-ui/icons/Settings'
import ResetIcon from '@material-ui/icons/RotateLeft'
import { version } from '../../package.json'

export default function Menu({ onReset, theme }) {
	let [open, setOpen] = useStore('menu', true)

	const items = [
		{
			key: '/route',
			text: 'Navigate To',
			Icon: EditLocationIcon,
		},
		{
			key: '/status',
			text: 'Status',
			Icon: PlaylistAddCheckIcon,
		},
		{
			key: '/settings',
			text: 'Settings',
			Icon: SettingsIcon,
		},
		{
			key: '/',
			text: 'Reset',
			Icon: ResetIcon,
			onClick: onReset,
		},
	]

	return (
		<>
			<IconButton onClick={() => setOpen(true)} style={{ color: theme.primary, position: 'absolute', top: '50%', left: 0, zIndex: 1e3, marginTop: -27 }} >
				<MenuIcon style={{ fontSize: "3rem" }} />
			</IconButton>
			<Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
				<div
					role="presentation"
					style={{ height: '100%' }}
					onClick={() => setOpen(false)}
					onKeyDown={() => setOpen(false)}
				>
					<List style={{ width: 250 }}>
						{items.map(i => 
							<ListItem component={Link} to={i.key} key={i.key} onClick={i.onClick} button>
								<ListItemIcon><i.Icon /></ListItemIcon>
								<ListItemText primary={i.text} />
							</ListItem>
						)}
					</List>
				</div>
				<div style={{ textAlign: 'right', paddingRight: 10, paddingBottom: 5 }}>
					v{version}
				</div>
			</Drawer>
		</>
	)
}