import { getCourse, getHeading, getWind, getCurrent, getAwa, getTwa } from "../algorithm"
import View from './Elements'

export default function Elements(ctx) {
	let { track, i, theme } = ctx
	let cog = getCourse(track, i)
	let { tws } = getWind(track, i)
	let { speed: currentSpeed, direction: currentDirection } = getCurrent(track, i)
	let heading = getHeading(track, i)
	let awa = getAwa(track, i)
	let twa = getTwa(track, i)
	return View({ cog, tws, awa, twa, heading, currentSpeed, currentDirection, theme })
}