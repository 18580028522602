import { GLTF } from './settings'
import { forEach } from 'rambda'

export let categories = [
    { name: "Dinghies"   , models: ["420", "49erFX", "Laser", "Moth", "Optimist"] },
	{ name: "Sport Boats", models: ["FlyingDutchman", "FlyingScot", "J70", "J80", "J88", "J105", "J111", "Melges24", "Melges32"] },
	{ name: "Keel Boats" , models: ["Bavaria40", "Couta", "Express27", "J22", "J24"] },
	{ name: "Multihulls" , models: ["F18"] },
	{ name: "Rowing"     , models: ["PilotGig"] },
	{ name: "Kitesurfing", models: ["Kitesurf"] },
]

export let models = {
    "420": {
        name: '420',
    	gltf: GLTF + '420/',
    	length: 4.2,
    	mast: 6.26,
    },
	"49erFX": {
        name: '49er FX',
    	gltf: GLTF + '49erFX/',
    	length: 4.99,
    	mast: 7.5,
    	speedScale: [3, 6, 9, 12, 15, 18],
    },
	Moth: {
        name: 'Moth',
    	gltf: GLTF + 'Moth/',
    	length: 3.4,
    	mast: 6.25,
    	speedScale: [6, 10, 14, 18, 22, 26],
    	wingAlt: 0.5,
    },
	Laser: {
        name: 'Laser',
    	gltf: GLTF + 'Laser/',
    	length: 4.2,
    	mast: 6.26,
    },
	Optimist: {
        name: 'Optimist',
    	gltf: GLTF + 'Optimist/',
    	length: 2.36,
    	mast: 2.26,
    	speedScale: [1, 1.5, 2, 2.5, 3, 3.5],
    },
	Bavaria40: {
        name: 'Bavaria 40',
    	gltf: GLTF + 'Bavaria40/',
    	length: 12.2,
    	mast: 17.5,
    },
	Couta: {
        name: 'Couta',
    	gltf: GLTF + 'Couta/',
    	length: 7.92,
    	mast: 9,
    },
	Express27: {
        name: 'Express 27',
    	gltf: GLTF + 'Express27/',
    	length: 8.3,
    	mast: 9.5,
    },
	J22: {
        name: 'J/22',
    	gltf: GLTF + 'J22/',
    	length: 6.7,
    	mast: 8,
    	spinnakerSkins: ['B', 'R', 'W', 'Y'],
    },
	J24: {
        name: 'J/24',
    	gltf: GLTF + 'J24/',
    	length: 7.3,
    	mast: 9,
    	spinnakerSkins: ['B', 'R', 'W', 'Y'],
    },
	FlyingDutchman: {
        name: 'Flying Dutchman',
    	gltf: GLTF + 'FlyingDutchman/',
    	length: 6.06,
    	mast: 7.01,
    },
	FlyingScot: {
        name: 'Flying Scot',
    	gltf: GLTF + 'FlyingScot/',
    	length: 5.8,
    	mast: 8.6,
    },
	J70: {
        name: 'J/70',
    	gltf: GLTF + 'J70/',
    	length: 7,
    	mast: 9,
    	spinnakerSkins: ['B', 'R', 'W', 'Y'],
    },
	J80: {
        name: 'J/80',
    	gltf: GLTF + 'J80/',
    	length: 8,
    	mast: 10,
    	spinnakerSkins: ['B', 'R', 'W', 'Y'],
    },
	J88: {
		name: 'J/88',
		gltf: GLTF + 'J80/',
	    length: 8.96,
		mast: 12,
		spinnakerSkins: ['B', 'R', 'W', 'Y'],
		polar: `
twa/tws;6;8;10;12;14;16;20;25
0;0;0;0;0;0;0;0;0;0
42.2;4.63;0;0;0;0;0;0;0;5
40.8;0;5.52;0;0;0;0;0;0;10
38.7;0;0;6.12;0;0;0;0;0;21
36.8;0;0;0;6.29;0;0;0;0;23
36.3;0;0;0;0;6.38;0;0;0;24
36.2;0;0;0;0;0;6.42;0;0;26
35.9;0;0;0;0;0;0;6.49;0;26
35;0;0;0;0;0;0;0;6.6;26
52;5.22;6.22;6.72;6.95;7.06;7.11;7.23;7.23
60;5.51;6.44;6.87;7.13;7.3;7.39;7.51;7.51
75;5.73;6.6;7;7.35;7.69;7.92;8.1;8.1
90;5.87;6.79;7.08;7.38;7.81;8.24;8.86;8.86
110;6.01;6.93;7.49;7.91;8.27;8.56;9.36;9.36
120;5.86;6.86;7.46;8.12;8.68;9.11;10.04;10.04
135;5.18;6.45;7.11;7.74;8.48;9.38;11.45;11.45
150;4.38;5.5;6.47;7.04;7.5;8.11;10.04;10.04
144;4.68;0;0;0;0;0;0;0;12
147.1;0;5.67;0;0;0;0;0;0;12
149.1;0;0;6.53;0;0;0;0;0;0.000001
150.9;0;0;0;6.98;0;0;0;0;-5
147;0;0;0;0;7.74;0;0;0;-6
144.6;0;0;0;0;0;8.62;0;0;-8
140.9;0;0;0;0;0;0;11.21;0;6
140;0;0;0;0;0;0;0;12;6
`
	},
	J105: {
        name: 'J/105',
    	gltf: GLTF + 'J105/',
    	length: 10.57,
    	mast: 15,
    	spinnakerSkins: ['B', 'R', 'W', 'Y'],
		polar: `
twa/tws;6;8;10;12;14;16;20
0;0;0;0;0;0;0;0
42.8;4.59;0;0;0;0;0;0
41.2;0;5.46;0;0;0;0;0
40.5;0;0;6.21;0;0;0;0
38.2;0;0;0;6.52;0;0;0
37.1;0;0;0;0;6.61;0;0
36.8;0;0;0;0;0;6.67;0
36.8;0;0;0;0;0;0;6.77
52;5.16;6.17;6.84;7.15;7.3;7.36;7.43
60;5.45;6.47;7.02;7.31;7.5;7.6;7.68
75;5.67;6.67;7.15;7.46;7.76;8.02;8.27
90;5.77;6.87;7.37;7.65;7.87;8.21;8.83
110;5.81;6.95;7.5;8.03;8.53;8.88;9.52
120;5.64;6.82;7.41;7.95;8.56;9.19;10.21
135;5.05;6.26;7.08;7.56;8.1;8.73;10.32
150;4.27;5.38;6.35;7.01;7.38;7.73;8.78
144;4.56;0;0;0;0;0;0
147.5;0;5.53;0;0;0;0;0
148.5;0;0;6.45;0;0;0;0
150.2;0;0;0;6.99;0;0;0
174;0;0;0;0;6.45;0;0
177;0;0;0;0;0;6.99;0
177;0;0;0;0;0;0;7.73
`
    },
	J111: {
        name: 'J/111',
    	gltf: GLTF + 'J111/',
    	length: 11.1,
    	mast: 16,
    	spinnakerSkins: ['B', 'R', 'W', 'Y'],
    },
	Melges24: {
        name: 'Melges 24',
    	gltf: GLTF + 'Melges24/',
    	length: 7.82,
    	mast: 9.5,
    	speedScale: [4, 6, 8, 10, 12, 14],
    },
	Melges32: {
		name: 'Melges 32',
		gltf: GLTF + 'Melges24/',
		length: 9.68,
		mast: 12,
		speedScale: [4, 6, 8, 10, 12, 14],
		polar: `
twa/tws;6;8;10;12;14;16;20
0;0;0;0;0;0;0;0
42.8;5.75;0;0;0;0;0;0
39;0;6.36;0;0;0;0;0
37.9;0;0;6.58;0;0;0;0
37.5;0;0;0;6.66;0;0;0
36.9;0;0;0;0;6.74;0;0
37.1;0;0;0;0;0;6.81;0
37.9;0;0;0;0;0;0;6.83
52;6.42;7.09;7.31;7.44;7.57;7.73;7.8
60;6.73;7.34;7.64;7.82;7.94;8.2;8.32
75;6.94;7.64;8.2;8.5;8.7;8.86;9.34
90;6.94;7.64;8.5;9.13;9.51;9.77;10.28
110;6.95;7.74;8.38;9.03;9.81;10.84;11.93
120;6.83;7.84;8.64;9.28;10.06;10.93;12.63
135;6.23;7.39;8.43;9.73;10.8;11.72;13.78
150;5.21;6.47;7.41;8.29;9.53;11.05;14.71
142;5.72;0;0;0;0;0;0
147.2;0;6.66;0;0;0;0;0
152.8;0;0;7.13;0;0;0;0
148;0;0;0;8.47;0;0;0
142;0;0;0;0;10.47;0;0
143.1;0;0;0;0;0;11.97;0
148.5;0;0;0;0;0;0;14.94
`
  	},
	F18: {
        name: 'F18',
    	gltf: GLTF + 'F18/',
    	length: 5.52,
    	mast: 9.15,
    	speedScale: [3, 6, 9, 12, 15, 18],
    },

	PilotGig: {
        name: 'Pilot Gig',
    	gltf: GLTF + 'PilotGig/',
    	length: 9.8,
    	mast: 1.5,
    	rigSkins: 0,
    },

	Kitesurf: {
        name: 'Kitesurf',
    	gltf: GLTF + 'Kitesurf/',
    	length: 8,
    	mast: 3,
    }
}

forEach((m, key) => m.key = key, models)
forEach(c => c.models = c.models.map(key => models[key]), categories)
models.default = models.Bavaria40
