// @ts-check
import { useEffect, useState, useCallback } from 'react'

export default function useBlink(count = 6, interval = 500) {
    const [blink, setBlink] = useState(0)
    useEffect(() => {      
        if (blink > 0) {
            let timer = setTimeout(() => {
                setBlink(blink-1) 
            }, interval)
			return () => clearTimeout(timer)
        } 
    }, [blink, interval])
    const startBlinking = useCallback(cnt => setBlink(cnt || count), [count])
    return [!(blink % 2), startBlinking]
}