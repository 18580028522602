import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { BrowserRouter as Router } from "react-router-dom"
import App from './App'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'
// import reportWebVitals from './reportWebVitals'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { Offline as OfflineIntegration } from "@sentry/integrations"

import * as Location from './location'
import * as Tools from './tools'
import * as Algorithm from './algorithm'

Object.assign(global, Tools, Location, Algorithm)

function fullscreen() {
	console.log('requestFullscreen')
	try {
		document.documentElement.requestFullscreen().catch(e => console.warn('requestFullscreen', e))
	} catch (e) {
		console.warn('requestFullscreen', e)
	}
}

if (process.env.NODE_ENV === 'production') {
	// fullscreen()
	document.onclick = fullscreen

	window.onerror = (msg, src, lineno, colno, err) => {
		document.write('<pre>' + err.stack + '</pre>')
		setTimeout(() => {
			fetch(window.location.href)
			.then(() => window.location.reload())
		}, 10e3)
	}

	Sentry.init({
		dsn: "https://5d10c313484742ae96d54ed3a8dfbf7e@o592487.ingest.sentry.io/5740895",
		release: process.env.REACT_APP_BUILT,
		environment: process.env.NODE_ENV,
		autoSessionTracking: true,
		sendDefaultPii: true,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1,

		integrations: [
			new Integrations.BrowserTracing(),
			new OfflineIntegration(),
		],
	})
}

ReactDOM.render(
	<StrictMode><Router><App /></Router></StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
