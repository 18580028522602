// @ts-check
import { useState, useCallback } from 'react'

try {
	var storage = window.localStorage
	storage.test = '1'
	if (storage.test !== '1') {
		storage = {}
	}
} catch(e) {
	storage = {}
}

/**
 * @param {string} key
 * @param {any} [val]
 */
export default function store(key, val) {
    return arguments.length === 1 ? store.get(key) : store.set(key, val)
}

/**
 * @param {string} key
 * @param {any} [def]
 * @return {any}
 */
store.get = function(key, def) {
    var val = storage[key]
    if (val === undefined || val === 'undefined') return def
    return JSON.parse(val)
}

/**
 * @param {string} key
 * @param {any} [val]
 */
store.set = function(key, val) {
    if (val === undefined) {
        delete storage[key]
    } else {
        storage[key] = JSON.stringify(val)
    }
}

/**
 * @param {string} key
 * @param {any} [def]
 */
export function useStore(key, def) {
	let [state, setState] = useState(store.get(key, def))
	let callback = useCallback(x => {
		store.set(key, x)
		setState(x)
	}, [key])
	return [state, callback]
}