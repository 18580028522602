import { last, median, pluck } from 'rambda'
import { signum } from '../tools'
import { getTarget } from '../algorithm'
import View from './Helming'

export default function Helming(ctx) {
	let { track, i, theme } = ctx
	const n = 20
	let history = track.slice(-n).map((x, j) => ({
		key: i < n ? j : i - n + j + 1,
		heel: x.roll,
		pitch: x.pitch,
	}))
	let heel = last(history).heel
	let pitch = last(history).pitch
	let targetHeel = getTarget(track, i).heel || signum(heel) * median(pluck('heel', history).map(Math.abs))
	// let targetPitch = median(pluck('pitch', history))
	let targetPitch = 0
	history = history.filter(({ key }) => key % 2)

	return View({ targetHeel, targetPitch, heel, pitch, history, theme })
}