import { calcMiddle } from '../location'
import { getWp, getPrevWp, getCourse, getSpeed, getWind, getCurrent, getLegSegments, getPrevLeg, getLaylineDirections, getRhumbDirection, getSow, getTimeToMark, getTarget, getLegPosSign, getSegment, getTimeToLayline, getTurn } from "../algorithm"
import View from './Navigation'

export default function Navigation(ctx) {
	let { track, i, theme, model } = ctx
	let position = track[i]
	let cog = getCourse(track, i, i-1)
	let sog = getSpeed(track, i)
	// console.log('Navigation.sog', track.length, track[track.length-1].time, sog)
	let { twd } = getWind(track, i)
	let sow = getSow(track, i)
	let { speed: currentSpeed, direction: currentDirection } = getCurrent(track, i)
	let segment = getSegment(track, i)
	let wp1 = getPrevWp(track, i)
	let wp2 = getWp(track, i)
	let rhumbDirection, laylines, segments, timeToMark, turn, timeToLayline, targetSpeed, legPosSign
	if (wp1 && wp2) {
		if (wp1.gate) wp1 = track[getPrevLeg(track, i).rounding]
		if (wp2.gate) wp2 = calcMiddle(wp2)
		legPosSign = getLegPosSign(track, i)
		rhumbDirection = getRhumbDirection(track, i)
		laylines = getLaylineDirections(track, i)
		segments = getLegSegments(track, i)
		timeToMark = getTimeToMark(track, i)
		timeToLayline = getTimeToLayline(track, i)
		turn = getTurn(track, i)
		targetSpeed = getTarget(track, i).sow
	}
	return View({ wp1, wp2, position, segment, cog, sog, twd, sow, targetSpeed, currentSpeed, rhumbDirection, currentDirection, legPosSign, laylines, segments, turn, timeToMark, timeToLayline, model, theme })
}