// @ts-check

/**
 * @param {number} a
 * @param {number} b
 * @param {number} [epsilon]
 * @return {boolean}
 */
export function equals(a, b, epsilon) {
	return typeof a === "number" && typeof b === "number"
		? Math.abs(a - b) < (epsilon || 1e-9)
		: a === b
}

/**
 * @param {Object} a
 * @param {Object} b
 * @return {boolean}
 */
export function changed(a, b) {
	let changed
	Object.keys(b).forEach(key => {
		if (!equals(a[key], b[key])) {
			a[key] = b[key]
			changed = true
		}
	})
	return changed
}

/**
 * @param {any[]} a
 */
export function compact(a) {
	var n = a.length
	var d = 0
	for (var i = 0; i < n; i++) {
		var val = a[i]
		if (val == null) {
			d++
		} else {
			a[i - d] = a[i]
		}
	}
	a.length = n - d
	return a
}

/**
 * @param {Object[]} a
 * @param {Object[]|Object} b
 * @return {Object[]}
 */
export function merge(a, b) {
	if (!Array.isArray(b)) b = [b]
	b.forEach(o => {
		var found = a.find(x => x.id === o.id)
		if (found) {
			Object.assign(found, o)
		} else {
			a.push(o)
		}
	})
	return a
}

/**
 * @param {number} x
 * @return {number}
 */
export function signum(x) {
	return x > 0 ? 1 : x < 0 ? -1 : 0
}

/**
 * @param {number} num
 * @param {number} [dec]
 * @return {number}
 */
export function round(num, dec) {
	if (num == null) return num
	if (!dec) dec = 0
	var scale = Math.pow(10, dec)
	return Math.round(num * scale) / scale
}

/**
 * @param {number} x
 * @param {number} y
 * @return {number}
 */
export function trunc(x, y) {
	return (x / y | 0) * y
}

/**
 * @param {number} start
 * @param {number} end
 * @param {number} now
 * @param {number} startValue
 * @param {number} endValue
 * @param {Object} [arithmetic]
 * @return {number}
 */
export function interpolate(start, end, now, startValue, endValue, arithmetic) {
	if (now < start) now = start
	if (now > end) now = end
	var k = (now - start) / (end - start + 1e-99)
	var d = arithmetic ? arithmetic['-'](endValue, startValue) : endValue - startValue
	var x = arithmetic ? arithmetic['+'](startValue, k * d) : startValue + k * d
	return x
}

/**
 * @param {any} n
 * @return {boolean}
 */
export function isNumber(n) {
	// return !isNaN(Number(n)) && isFinite(n)
	// eslint-disable-next-line eqeqeq
	return parseFloat(n) == n
}

/**
 * @param {number} x
 * @param {string} unit
 * @param {string} units
 * @return {string}
 */
export function singleOrPlural(x, unit, units) {
	if (!x) return ''
	if (x === 1) return '1 ' + unit
	if (!units) units = unit + 's'
	return x + ' ' + units
}

/**
 * @param {Object} cache
 * @param {string} key
 * @param {function} calc
 * @return {any}
 */
export function memo(cache, key, calc) {
	var x = cache[key]
	return x !== undefined ? x : cache[key] = calc()
}

/**
 * @param {any[]} xs
 * @return {any}
 */
export function nvl(...xs) {
	return xs.find(x => x != null)
}

/**
 * @param {any[]} a
 * @param {number} i
 * @param {number} step
 * @param {function} fn
 * @return {any}
 */
export function explore(a, i, step, fn) {
	if (!step) return fn(a[i], i)
	var last = a.length - 1
	for (; i >= 0 && i <= last; i += step) {
		var result = fn(a[i], i)
		if (result !== undefined) {
			return result
		}
	}
}

/**
 * @param {Object[]} track
 * @param {number} i
 * @param {number} dt
 * @param {function} [fn]
 * @return {any}
 */
export function pass(track, i = 0, dt, fn) {
	var step = signum(dt)
	var time = track[i].time + dt
	var last = track.length - 1
	return explore(track, i, step, (point, i) => {
		var edge = step > 0 ? (i === last || track[i + 1].time > time) : step < 0 ? (i <= 1 || point.time < time) : true
		if (edge && !fn) return i
		if (fn) var result = fn(point, i)
		if (edge && result === undefined) return null
		return result
	})
}

/**
 * @param {Object[]} track
 * @param {number} i
 * @param {number} time
 * @param {function} [fn]
 * @return {any}
 */
export function pass2(track, i = 0, time, fn) {
	return pass(track, i, time - track[i].time, fn)
}

/**
 * @param {string} s
 * @param {boolean} decode
 * @return {Object[]}
 */
export function tsv(s, decode) {
	if (!s) return []
	var a = s.split('\n')
	var n = 0
	var h
	a.forEach(r => {
		if (r.charCodeAt(r.length - 1) === 13) r = r.slice(0, -1)
		if (r === '') return
		var b = r.split('\t')
		if (!h) {
			h = b
			return
		}
		var o = {}
		if (decode === false) {
			b.forEach((x, i) => {
				o[h[i]] = x
			})
		} else {
			b.forEach((x, i) => {
				o[h[i]] = decodeURIComponent(x)
			})
		}
		a[n++] = o
	})
	a.length = n
	return a
}

/**
 * @param {Object[]} xs
 * @param {number|string} id
 * @return {Object}
 */
export function findById(xs, id) {
	return xs.find(x => x.id === id)
}

/*
function benchmark(times, fn) {
	console.time(1)
	while(times--) fn()
	console.timeEnd(1)
}

function speakableTime(x) {
	x = XDate(x)
	return singleOrPlural(x.getUTCHours(), 'hour') + ' '
		+ singleOrPlural(x.getUTCMinutes(), 'minute') + ' '
		+ singleOrPlural(x.getUTCSeconds(), 'second')
}

function audio(url) {
	var player = audio.player
	if (!player) {
		player = new Audio()
		audio.player = player
	}
	player.src = url
	player.play()
}

audio.playing = function() {
	var player = audio.player
	return player && !player.ended
}

function tts(t) {
	audio('http://tts-api.com/tts.mp3?q=' + encodeURIComponent(t))
}
*/
