import { useState } from 'react'
import { useInterval } from 'react-use'
import { format } from 'date-fns'
import { round } from './tools'

const INTERVAL = 60
function formatTrack(boatName = 'RQPod', track, from, till) {
	let header = boatName + ' ' + format(track[0].time, 'y-MM-dd HH:mm:ssxx')
	let fields = '\ntime,lat,lon,accuracy,heading,pitch,roll,sow,windAngle,windSpeed\n'
	let chunk = track.slice(from, till)
	let rows = chunk.map(x => `${format(x.time, 'y-MM-dd HH:mm:ssxxx')},${round(x.lat, 6)},${round(x.lon, 6)},${round(x.accuracy)},${round(x.heading)},${round(x.pitch)},${round(x.roll)},${round(x.sow, 1)},${round(x.awa)},${round(x.aws, 1)}`)
	return header + fields + rows.join('\n').replace(/,NaN/g, ',').replace(/,null/g, ',').replace(/,undefined/g, ',')
}

export default function useUpload(ctx) {
	let [status, setStatus] = useState({ uploaded: 0, error: '' })
	let { uploaded } = status
	useInterval(() => {
		let { track, settings, userId } = ctx
		if (userId && track.current > uploaded + INTERVAL / 2) {
			upload()
		}
		async function upload() {
			let { boatName } = settings
			let from = uploaded
			let till = Math.min(uploaded + 3600, track.length)
			let body = formatTrack(boatName, track, from, till)
			try {
				let x = await fetch('https://raceqs.net/TrackData.ashx?userId=' + userId, {
					method: 'POST',
					body,
				})
				let text = await x.text()
				if (!x.ok) {
					throw new Error(`${x.status} ${text}`)
				}
				setStatus({ uploaded: till })
				console.log('track uploaded', from, till, body.length, new Date(track[till - 1].time).toISOString(), text)
			} catch(e) {
				setStatus({ uploaded: from, error: e.toString() })
				console.warn('track upload error', from, till, body.length, new Date(track[till - 1].time).toISOString(), e)
			}
		}
	}, ctx ? INTERVAL * 1e3 : null)
	return status
}