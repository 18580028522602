// @ts-check
export let { host, href, hostname, protocol, hash } = global.location
hash = hash.slice(1)

export let LOCALHOST = host.startsWith('localhost')

export let SEC = 1e3
export let MIN = 60 * SEC
export let HOUR = 60 * MIN
export let DAY = 24 * HOUR

let settings = {
	SIGNALK_HOST: process.env.NODE_ENV === 'production' ? '192.168.4.1' : hostname,
	FONT_SIZE: 120,
	LINE_WIDTH: 6,
	DEBUG: LOCALHOST,
	LOAD_LIMIT: 200,
	STREAMING_DELAY: 5 * MIN,
	POLL_INTERVAL: 60 * SEC,
	TINY_WINDOW: 5,
	SMALL_WINDOW: 15,
	MEDIUM_WINDOW: 30,
	SMALL_SHIFT: 3,
	LARGE_SHIFT: 10,
	MIN_TACKING_ANGLE: 45,
	MAX_TACKING_ANGLE: 135,
	MIN_JIBING_ANGLE: 20,
	MAX_JIBING_ANGLE: 100,
	MIN_MANEUVER_SHIFT: 30,
	MAX_ROLL: 50,
	MAX_DRIFT: 30,
	MAX_PITCH: 10,
	MIN_MOUNTED_DURATION: 10 * MIN,
	TRACK_WIDTH: 3,
	VECTOR_WIDTH: 3,
	VIEW_TRANSITION_TIME: 1000,
}

hash.split('&').forEach(x => {
	let [k, v] = x.split('=')
	settings[k.toUpperCase()] = isNaN(Number(v)) ? v : parseFloat(v)
})

export let {
	SIGNALK_HOST,
	FONT_SIZE,
	LINE_WIDTH,
	DEBUG,
	LOAD_LIMIT,
	STREAMING_DELAY,
	POLL_INTERVAL,
	TINY_WINDOW,
	SMALL_WINDOW,
	MEDIUM_WINDOW,
	SMALL_SHIFT,
	LARGE_SHIFT,
	MIN_TACKING_ANGLE,
	MAX_TACKING_ANGLE,
	MIN_JIBING_ANGLE,
	MAX_JIBING_ANGLE,
	MIN_MANEUVER_SHIFT,
	MAX_ROLL,
	MAX_DRIFT,
	MAX_PITCH,
	MIN_MOUNTED_DURATION,
	TRACK_WIDTH,
	VECTOR_WIDTH,
	VIEW_TRANSITION_TIME,
	LITE,
	SIM,
} = settings

export let PI_180 = Math.PI / 180
export let PI_360 = Math.PI / 360

export let IMG = 'img/'
export let TACK_ICON = IMG + 'tack.png'
export let JIBE_ICON = IMG + 'jibe.png'
export let AREA_ICON = 'http://maps.google.com/mapfiles/kml/pal2/icon18.png'

export let GLTF = 'gltf/'
export let RCB_MODEL = GLTF + 'rcb.glb'
export let PIN_MODEL = GLTF + 'pin.glb'

export let DIM_USER = 'dimension1'
export let DIM_MODE = 'dimension2'
export let DIM_ENGINE = 'dimension3'

export let ROOT = href.substr(0, href.lastIndexOf('/') + 1)
export let COM = LOCALHOST ? 'https://raceqs.com/' : protocol + '//' + host + '/'
export let API = COM + 'rest/'
export let NET = 'http://raceqs.net/' //COM.replace('.com', '.net')

export let TINY_SPAN = TINY_WINDOW * SEC
export let SMALL_SPAN = SMALL_WINDOW * SEC
export let MEDIUM_SPAN = MEDIUM_WINDOW * SEC

export let DEVICE_WIDTH = (1280-77)/720*1404/3
export let DEVICE_HEIGHT = 1404/3

// export let DEVICE_WIDTH = window.innerWidth/3
// export let DEVICE_HEIGHT = window.innerHeight/3

// export let DEVICE_WIDTH = window.innerWidth/window.innerHeight*1404/3
// export let DEVICE_HEIGHT = 1404/3

// export let DEVICE_WIDTH = 1872/3
// export let DEVICE_HEIGHT = 1404/3