import Header from '../Header'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '../ViewComponents/Input'
import Typography from '@material-ui/core/Typography'

export default function Settings({ settings = { theme: 'dark'}, onSettings, models }) {
	const formStyle = {
		padding: 50,
        paddingLeft: "15%",
        paddingRight: "15%",
		boxSizing: "border-box",
		display: "flex",
        flexDirection: "column",
		fontFamily: "Arial",
		lineHeight: "initial",
        backgroundColor: "white",
        overflow: "auto",
	}

    const Control = ({value, text}) => {
        return (
            <FormControlLabel 
                value={value} 
                control={<Radio /> } 
                label={<Typography style={{ fontSize: 30 }} >{text}</Typography >}
            />
        )
    }
        
	models = Object.keys(models).map(key => models[key]).filter(m => m.polar)

	return (
		<>
			<Header>Settings</Header>
			<form style={formStyle}>
				<Input 
					label="Boat name"
					value={settings.boatName}
					onChange={ev => onSettings({ boatName: ev.target.value })}
				/> 
				<FormControl style={{ marginBottom: 40 }}>
					<InputLabel style={{ fontSize: 30 }}>Boat model</InputLabel>
					<Select value={settings.model || ''} onChange={ev => onSettings({ model: ev.target.value })} style={{ fontSize: 30, marginTop: 30  }}>
						{models.map(({ key, name }) => <MenuItem value={key} key={key}>{name}</MenuItem>)}
					</Select>
				</FormControl>  
				<Input 
					label="Sail Number"
					value={settings.sailNumber}
					onChange={ev => onSettings({ sailNumber: ev.target.value })}
				/>          
				<Input 
					label="raceQs e-mail"
					value={settings.email}
					onChange={ev => onSettings({ email: ev.target.value })}
				/>
				<Input 
					label="GPS Distance from Bow"
					value={settings.gpsDistanceFromBow}
					onChange={ev => onSettings({ gpsDistanceFromBow: ev.target.value })}
					type="number"
				/>  
				<FormControl component="fieldset" style={{ marginTop: 20 }}>
					<FormLabel component="legend" style={{ fontSize: 30, marginBottom: 10 }}>Theme</FormLabel>
					<RadioGroup 
						aria-label="theme" 
						name="theme" 
						value={settings.theme} 
						onChange={ev => onSettings({ theme: ev.target.value })}
					>
						<Control value="dark" text="Dark"/>
						<Control value="light" text="Light"/>
					</RadioGroup>
					</FormControl>
			</form>
		</>
	)
}