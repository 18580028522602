export default function Cross({ x, y, size, thickness = 10, ...rest }) {
	let side = size / 2 - thickness / 2
	return (
		<path {...rest} d={`
                M ${x + thickness / 2} ${y - size / 2}
				v ${side}
				h ${side}
				v ${thickness}
				h ${-side}
				v ${side}
				h ${-thickness}
				v ${-side}
				h ${-side}
				v ${-thickness}
				h ${side}
				v ${-side}
				z
		`} />
	)
}
