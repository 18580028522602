import './index.css'
import { useState } from 'react'
import { useSetup } from '../useSetup'
import { Switch, Route } from "react-router-dom"
import Boards from '../Boards'
import RouteSetup from '../RouteSetup'
import Status from '../Status'
import SettingsForm from '../SettingsForm'

export default function App({ replay }) {
	let [state, setState] = useState({})
	if (replay) global.Dashboard.setState = setState
	let ctx = useSetup(replay && state)
	return (ctx &&
		<Switch>
			<Route path="/route"><RouteSetup {...ctx} /></Route>
			<Route path="/status"><Status {...ctx} /></Route>
			<Route path="/settings"><SettingsForm {...ctx} /></Route>
			<Route path="/"><Boards {...ctx} /></Route>
		</Switch>
	)
}