import SetupView from './Setup'
import AssistView from './Assist'
import { toRadians, calcDirection, calcDistance, angle180, calcShift } from '../location'
import { FONT_SIZE, LINE_WIDTH, DEVICE_HEIGHT, DEVICE_WIDTH} from '../settings'
import { Display, NumberDisplays } from '../ViewComponents/NumberDisplays'
import { Button } from "../ViewComponents/Buttons"
import { useEffect, useState } from "react"
import useBlink from '../useBlink'
import { formatTime } from "../ViewComponents/tools"
import IconButton from '@material-ui/core/IconButton'
import MoreIcon from '@material-ui/icons/KeyboardArrowUp'
import LessIcon from '@material-ui/icons/KeyboardArrowDown'

export default function Start(ctx) {
    let { timeToStart, rcb, pin, position, onReset, gun, model, twd, theme, setTimeToStart } = ctx
    // const [orientation, setOrientation] = useState('h')
    //const biasBasis = windAngle > 0 ? from : till
    // let biasInBoats = (bias/boatLength).toFixed(2)
    // let remainder =  biasInBoats % 1 < 0.25 ? 0 : biasInBoats % 1 < 0.75 ? 0.5 : 1
    // biasInBoats = ~~biasInBoats + remainder
    // const switchOrientation = () => {
    //     setOrientation(orientation == 'v' ? 'h': 'v')
    // }
    // const orientation = 'v'        
    // const viewBox = orientation === 'h' ? 
    //     `${-DEVICE_HEIGHT/3} 0 ${DEVICE_HEIGHT} ${DEVICE_WIDTH}` : 
    //     `${-DEVICE_WIDTH/2} 0 ${DEVICE_WIDTH} ${DEVICE_HEIGHT}`

    const [timeDistanceVisible, setBlink] = useBlink(20, 500)
    const [distanceAtStart, setDistanceAtStart] = useState()
	const justStarted = timeToStart <= 0 && timeToStart > -10e3
    let distanceToStart = rcb && pin && calcDistance(position, { ...rcb, gate: pin }, true)
    
	useEffect(() => {
		if (justStarted) {         
            setBlink()
            setDistanceAtStart(distanceToStart)
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [justStarted, setBlink])

    
    if (!timeToStart) return null
    
    const behindLine = position && rcb && pin && calcShift(calcDirection(position, rcb), calcDirection(position, pin)) < 0 ? false : true 
    if (behindLine) {
        //box = startBoxWidth + distanceToStart + boatLength
        distanceToStart = -distanceToStart
    }

    const timeText = justStarted 
        ? '0:00' 
        : timeToStart >= 0 
            ? formatTime(timeToStart) 
            : formatTime(timeToStart, "hour")
    const boatLength = model.length
    const startLineDirection = rcb && pin && calcDirection(rcb, pin)
	const windAngle = rcb && pin && twd && angle180(twd - 180 - startLineDirection - 90)
	const bias = rcb && pin && windAngle && Math.abs(Math.tan(toRadians(windAngle)) * calcDistance(rcb, pin))
    // const biasBasis = windAngle > 0 ? from : till 

    let setup = (timeToStart > 120e3 || !pin || !rcb) ? true : false
    // let smallBtnSize = {height: 40, width: 100}

    let timerButtons = (
		<div key="timer" style={{ display: "flex" }} >
			<Button
				text={timeText}
				fontSize={timeToStart < 0 && !justStarted && 70}
				size={{ height: 150, width: 320 }}
				onLongPress={onReset}
				onClick={gun}
				zIndex="-1"
			/>
			{setup &&
				<div style={{ display: "flex", flexDirection: 'column', zIndex: 1e3 }}>
					<IconButton onClick={() => setTimeToStart(x => x + 60e3)} style={{ pointerEvents: "auto" }}>
						<MoreIcon style={{ fontSize: "3.2rem" }} />
					</IconButton>
					<IconButton onClick={() => setTimeToStart(x => x - 60e3)} style={{ pointerEvents: "auto" }}>
						<LessIcon style={{ fontSize: "3.2rem" }} />
					</IconButton>
				</div>
			}
        </div>
	)		

    let displays = [
        windAngle && windAngle < 0 && isFinite(bias) && bias < 1e3 &&
            <Display value={bias.toFixed()} text="Bias m" textPlace="bottom" place="left" key="PIN"/>,
		windAngle && windAngle > 0 && isFinite(bias) && bias < 1e3 &&
            <Display value={bias.toFixed()} text="Bias m" textPlace="bottom" key="RCB"/>,
        timerButtons,
        timeDistanceVisible && isFinite(distanceToStart) && <Display 
            value={justStarted && distanceAtStart ? distanceAtStart.toFixed() : distanceToStart.toFixed()} 
            text="DTL m"
            key="distance"
        />,
    ]

    return (
        <svg 
			viewBox={`${-DEVICE_WIDTH/2} 0 ${DEVICE_WIDTH} ${DEVICE_HEIGHT}`}  
			style={{ 
                backgroundColor: theme.background, 
                height: "100%", 
                width: "100%", 
                color: theme.primary, 
                fontSize: FONT_SIZE,
                fontFamily: '"Arial Narrow", sans-serif-condensed, Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                fontStretch: 'condensed',
            }} 
			stroke={theme.primary} fill={theme.primary} strokeWidth={LINE_WIDTH}
            /*transform={orientation === 'h' ? `rotate(90, 0, ${DEVICE_WIDTH/2})` : "" }*/
		>
            {setup
                ? <SetupView {...{ windAngle, ...ctx }} /> 
                : <AssistView {...{ distanceToStart, startLineDirection, boatLength, ...ctx }} />
            }
            <NumberDisplays height={DEVICE_HEIGHT} width={DEVICE_WIDTH} theme={theme}>                
                {displays}
            </NumberDisplays> 
        </svg>
    )
}


