import { FONT_SIZE, DEVICE_HEIGHT, DEVICE_WIDTH, LINE_WIDTH, MIN } from '../settings'
import { formatTime } from '../ViewComponents/tools'
import NonRacing from './NonRacing'
import Racing from './Racing'
import { Display, NumberDisplays } from '../ViewComponents/NumberDisplays'
import { signum, isNumber } from '../tools'

export default function Navigation({ wp1, wp2, position, cog, twd, sog, turn, rhumbDirection, currentDirection, laylines, segments, timeToMark, timeToLayline, model, theme, legPosSign, segment }) {
    // // Rotation
    // const centerY = DEVICE_HEIGHT/2  
    // const windRhumbAngle = angle180(twd - rhumbDirection - 180)
    // const sideRotation = (viewAngle === 90 ||  viewAngle === -90) ? true : false
    // const viewHeight = sideRotation ? DEVICE_WIDTH : DEVICE_HEIGHT
    // const viewWidth = DEVICE_WIDTH  

    // let rotate = `rotate(${viewAngle} 0 ` + (sideRotation ? '0' : centerY) + ') '
    // if (sideRotation) {
    //     rotate = `translate(${viewAngle === 90 ? viewWidth/2 : -viewWidth/2} ${DEVICE_HEIGHT/2})` + rotate
    // }
    let navigating = wp1 && wp2
	let boatLength = model.length 
	let rounding = timeToMark < 3 * MIN

	let turnValue = Math.abs(turn).toFixed()
	turnValue = turn > 0 ? turnValue + '>' : '<' + turnValue

    return (
		<svg 
			viewBox={`${-DEVICE_WIDTH/2} 0 ${DEVICE_WIDTH} ${DEVICE_HEIGHT}`}  
			style={{ 
                backgroundColor: theme.background, 
                height: "100%", 
                width: "100%", 
                color: theme.primary, 
                fontSize: FONT_SIZE
            }} 
			stroke={theme.primary} fill={theme.primary} strokeWidth={LINE_WIDTH}
		>
            {navigating 
				? Racing({ wp1, wp2, position, cog, rhumbDirection, laylines, segments, theme, height: DEVICE_HEIGHT, rounding, boatLength, segment, currentDirection, twd })
				: NonRacing({ cog, twd, currentDirection, theme, DEVICE_HEIGHT })
            }

			<NumberDisplays height={DEVICE_HEIGHT} width={DEVICE_WIDTH} theme={theme}>
				{isNumber(turn) && <Display value={turnValue} text={"Turn \u00B0"} place="left" textPlace="bottom" />}
				{rounding || legPosSign === 0
					? (timeToMark >= 0 && <Display value={formatTime(timeToMark)} text="TTM" textPlace="bottom" />)
					: (timeToLayline >= 0 && <Display value={formatTime(timeToLayline)} text="TTLL" textPlace="bottom"/>)
				}
				{/*isNumber(currentDirection) && <Display value={currentDirection.toFixed()} text="Current Direction" textPlace="bottom" />*/}
				{isNumber(sog) && <Display value={sog.toFixed(1)} text="SOG kn" place="left" />}
				{isNumber(cog) && <Display value={cog.toFixed()} text={`COG \u00B0`} />}
                {/* {(!nonRacing && targetSpeed >= 0 && <Display value={targetSpeed.toFixed(1)} text="Target Speed" place="left" textPlace="bottom" unit="kn"/>)}
                {(sow >= 0 && <Display value={sow.toFixed(1)} text="Speed" unit="kn" textPlace="bottom"/> )}            
                {(currentSpeed >= 0 && <Display value={currentSpeed.toFixed(1)} text="Current Speed" place="left" unit="kn"/>)}
                {rounding 
                    ? (timeToMark >= 0 && <Display value={formatTime(timeToMark)} text="TIME TO MARK"/>)
                    : legPosSign === 1 
                        ? (awa >= 0 && <Display value={Math.abs(awa)+"\u00B0"} text="AWA"/>)
                        : (twa >= 0 && <Display value={Math.abs(twa)+"\u00B0"} text="TWA"/>)
                }                */}
            </NumberDisplays>	
		</svg>
    )
}